import Chart from "react-google-charts";
import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import ReactDOMServer from 'react-dom/server';
import UserContext from "../../contexts/UserContext";
import API from "../../utils/ApiParams";
import "./DashboardNewsletters.css";
import Status from "../Status";
import slugify from 'slugify';

export default function DashboardNewsletters() {
    const user = useContext(UserContext);

    const [article,setArticle] = useState(null);
    const [newsletters,setNewsletters] = useState([]);
    const [lastNewsletter,setLastNewsletter] = useState({})
    const [openedData,setOpenedData] = useState([]);
    const [reboundData,setEngagementData] = useState([]);
    const [openedColor,setOpenedColor] = useState(null);
    const [reboundColor,setEngagementColor] = useState(null);

    const [fromDate,setFromDate] = useState(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0,10));
    const [toDate,setToDate] = useState(new Date().toISOString().slice(0,10));
    const [toIncludedDate, setToIncludedDate] = useState(null);
    async function getNewsletters(){
        let toDateLocal = new Date(new Date(toDate));
        toDateLocal.setDate(toDateLocal.getDate()+1);
        toDateLocal = toDateLocal.toISOString().slice(0,10);
        setToIncludedDate(toDateLocal);
        const url = `newsletters?status.index=2&sendingDate%5Bbefore%5D=${toDateLocal}&sendingDate%5Bafter%5D=${fromDate}&order%5BsendingDate%5D=asc`;
        const response = await API.get(url);
        return response.data;
    }
    useEffect(()=>{
        async function getArticle() {
            let url = "articles?isArchived=false&status.index=2&order%5BcreationDate%5D=desc&itemsPerPage=1";
            if(user.role === 2){
                url += "&author.id="+user.id;
            }
            const response = await API.get(url);
            return response.data[0];
        }
        async function getLastNewsletter(){
            const response = await API.get("newsletters?status.index=2&exists%5BsendingDate%5D=true&order%5BsendingDate%5D=desc&itemsPerPage=1");
            return response.data[0];
        }
        getArticle().then(data => {
            if(data) {
                data.categories.sort((a, b) => {
                    return a.position - b.position
                })
                setArticle(data)
            }
        });
        getNewsletters().then(data=> setNewsletters(data));
        getLastNewsletter().then(data=>setLastNewsletter(data));
    }, [user.id, user.role])

    useEffect(()=>{
        getNewsletters().then(data=> setNewsletters(data));
    },[fromDate,toDate])
    useEffect(()=>{
        if(newsletters && newsletters.length>0){
            let tempOpenedData = [
                [{type: 'date', label: 'x'}, {type: 'number', label: "Taux d'ouverture"},
                    { role: "tooltip", type: "string", p: { html: true } }]
            ];
            let tempEngagementData = [
                [{type: 'date', label: 'x'}, {type: 'number', label: 'Taux d\'engagement'},
                    { role: "tooltip", type: "string", p: {html: true} }]
            ];
            function getToolTip(newsletter, text, value){
                return(
                    <div className={"chartTooltip"}>
                        <p className={"chartTooltipTitle"}>Newsletter n°{newsletter.id} - {newsletter.object}</p>
                        <div>Envoyé le {new Date(newsletter.sendingDate).toLocaleDateString()}</div>
                        <p>{text} {value}</p>
                    </div>

                )
            }
            newsletters.forEach((nl, key)=>{
                let date = new Date(nl.sendingDate);
                if(tempOpenedData.length>1)
                    if (tempOpenedData[tempOpenedData.length-1][0].toISOString() === date.toISOString())
                        date = new Date(new Date(nl.sendingDate).getTime() + 3*60*60*1000);
                tempOpenedData.push([date, (nl.uniqueViewMail/nl.sentMail*100),
                    ReactDOMServer.renderToStaticMarkup(getToolTip(nl,"Nombre d'ouvertures :", nl.uniqueViewMail))]);
                tempEngagementData.push([date, (nl.uniqueEngagementMail/nl.sentMail*100),
                    ReactDOMServer.renderToStaticMarkup(getToolTip(nl,"Nombre d'engagements :", nl.uniqueEngagementMail))]);
            })

            setOpenedData(tempOpenedData);
            setEngagementData(tempEngagementData);
        }
    },[newsletters])
    useEffect(()=>{
        if (lastNewsletter && Object.keys(lastNewsletter).length>0) {
            const uniqueViewMailPercent = lastNewsletter.uniqueViewMail / lastNewsletter.sentMail * 100;
            const uniqueEngagementMailPercent = lastNewsletter.uniqueEngagementMail / lastNewsletter.sentMail * 100;

            switch (true) {
                case (uniqueViewMailPercent < 10):
                    setOpenedColor("#DC052C");
                    break;
                case (uniqueViewMailPercent < 50):
                    setOpenedColor("orange");
                    break;
                case (uniqueViewMailPercent >= 50):
                    setOpenedColor("green");
                    break;
                default:
                    setOpenedColor("#F8F8F8");
                    break;
            }

            switch (true) {
                case (uniqueEngagementMailPercent < 2):
                    setEngagementColor("#DC052C");
                    break;
                case (uniqueEngagementMailPercent < 10):
                    setEngagementColor("orange");
                    break;
                case (uniqueEngagementMailPercent >= 10):
                    setEngagementColor("green");
                    break;
                default:
                    setEngagementColor("#F8F8F8");
                    break;
            }
        }
    },[lastNewsletter])

    return(
        <div className={"col-10"}>
            <div className={"filterBar"}>
                <label>
                    <span className={"dashboardFilterText"}>Date de début</span>
                    <input className={`customInput`}
                           type="date" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
                </label>
                <label>
                    <span style={{marginLeft:"2rem"}} className={"dashboardFilterText"}>Date de fin</span>
                    <input className={`customInput`}
                           type="date" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
                </label>

            </div>
            <div className={"flex"} style={{justifyContent:"space-evenly"}}>
                <div className={"col-7 tile dashboardTile"}>
                    <p className={"chartTitle"}>Taux d'ouverture</p>
                    <p className={"chartSubtitle"}>Le pourcentage de mails ouverts</p>
                    {openedData.length>0 &&
                    <div className={"lineChart"}>
                        <Chart
                            chartLanguage="fr"
                            width={'100%'}
                            height={'100%'}
                            chartType="LineChart"
                            loader={<div>Chargement...</div>}
                            data={openedData}
                            options={{
                                animation: {
                                    duration: 1000,
                                    easing: 'out',
                                },
                                pointSize: 5,
                                isStacked: true,
                                legend: {position: 'top', maxLines: 2},
                                hAxis: {gridlines: {multiple: 1},
                                    format: 'd/MM/yy',
                                    viewWindow: {
                                        min: new Date(fromDate),
                                        max: new Date(toIncludedDate)
                                    }},
                                vAxis: {minValue: 0, maxValue: 100},
                                tooltip: { isHtml: true, trigger: "visible" },
                                series: {
                                    0: {color: '#DC052C'},
                                }
                            }}
                        />
                    </div>
                    }
                </div>

                <div className={"col-4 tile dashboardTile"}>
                    <p className={"chartTitle"}>Newsletter n°{lastNewsletter?lastNewsletter.id:""}</p>
                    {lastNewsletter &&
                    <div className={"chartWrapper"}>
                        <div className={"flex"}>
                            <div className="col-6">
                                <div className={"text-center"}>
                                    Statut
                                </div>
                                <div className={"text-center chartStatus"}>
                                    {lastNewsletter.status &&
                                    <Status status={lastNewsletter.status.index}/>
                                    }
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={"text-center"}>
                                    Nombre d'envois
                                </div>
                                <div className={"text-center chartDelivered"}>
                                    {lastNewsletter.deliveredMail}/{lastNewsletter.sentMail}
                                </div>
                            </div>
                        </div>
                        <div className={"flex"}>
                            <div className={"pieChart col-6"}>
                                <Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="PieChart"
                                    loader={<div>Chargement...</div>}
                                    data={[['Taux d\'ouverture', 'Percentage'], ['', lastNewsletter.uniqueViewMail / lastNewsletter.sentMail * 100], ['', 100 - lastNewsletter.uniqueViewMail / lastNewsletter.sentMail * 100]]}
                                    options={{
                                        legend: 'none',
                                        pieSliceText: 'none',
                                        pieStartAngle: 0,
                                        pieHole: 0.90,
                                        tooltip: {trigger: 'none'},
                                        slices: {
                                            0: {color: `${openedColor?openedColor:"#F8F8F8"}`},
                                            1: {color: '#F8F8F8'},
                                        },
                                    }}
                                />
                                <div className={"PieChartTitle"}>Taux d'ouverture</div>
                                <div style={{color:openedColor}} className={"pieChartText"}>
                                    {Math.round((lastNewsletter.uniqueViewMail / lastNewsletter.sentMail * 100)*1e1)/1e1}%
                                </div>
                            </div>
                            <div className={"pieChart col-6"}>
                                <Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="PieChart"
                                    loader={<div>Chargement...</div>}
                                    data={[['Taux d\'engagement', 'Percentage'], ['', lastNewsletter.uniqueEngagementMail / lastNewsletter.sentMail * 100], ['', 100 - lastNewsletter.uniqueEngagementMail / lastNewsletter.sentMail * 100]]}
                                    options={{
                                        legend: 'none',
                                        pieSliceText: 'none',
                                        pieStartAngle: 0,
                                        pieHole: 0.90,
                                        tooltip: {trigger: 'none'},
                                        slices: {
                                            0: {color: `${reboundColor?reboundColor:"#F8F8F8"}`},
                                            1: {color: '#F8F8F8'},
                                        },
                                    }}
                                />
                                <div className={"PieChartTitle"}>Taux d'engagement</div>
                                <div style={{color:reboundColor}} className={"pieChartText"}>
                                    {Math.round((lastNewsletter.uniqueEngagementMail / lastNewsletter.sentMail * 100) *1e1)/1e1}%
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <div className={"col-7 tile dashboardTile"}>
                    <p className={"chartTitle"}>Taux d'engagement </p>
                    <p className={"chartSubtitle"}>Le pourcentage d’utilisateurs qui ont cliqué sur un lien dans la newsletter</p>

                    {reboundData.length>0 &&
                    <div className={"lineChart"}>
                        <Chart
                            width={'100%'}
                            height={'100%'}
                            chartType="LineChart"
                            loader={<div>Chargement...</div>}
                            data={reboundData}
                            options={{
                                animation: {
                                    duration: 1000,
                                    easing: 'out',
                                },
                                pointSize: 5,
                                isStacked: true,
                                legend: {position: 'top', maxLines: 2},
                                hAxis: {gridlines: {multiple: 1},
                                    format: 'd/MM/yy',
                                    viewWindow: {
                                        min: new Date(fromDate),
                                        max: new Date(toIncludedDate)
                                    }},
                                vAxis: {minValue: 0, maxValue: 100},
                                tooltip: { isHtml: true, trigger: "visible" },
                                series: {
                                    0: {color: '#DC052C'},
                                }
                            }}
                        />
                    </div>
                    }
                </div>

                <div className={"col-4 tile dashboardTile dashboardFpArticle"}>
                    {article ?
                        <Link style={{height: "100%"}}
                              to={{
                            pathname: "/articles/creation",
                            state: {id: article.id, action: "edit"}
                        }}>

                            <div className={"fpLinearGradient"}>
                                <img className={"fpImage"}
                                     src={process.env.REACT_APP_API_URL + "images/" + article.coverImage.id + "/" + article.coverImage.name}
                                     alt=""
                                />
                            </div>
                            <div className={"fpBloc"}>
                                <p className={"fp"}>Dernier article publié</p>
                                <div>
                                    <div className={"fpCategories"}>
                                        {article.categories.map(category => {
                                            return (
                                                <span key={category.id}
                                                      className={`category-${category.id}-full fpCategory`}>{category.name}</span>
                                            )
                                        })}
                                    </div>
                                    <div className={`category-${article.categories[0].id}-text fpTitle`}>
                                        {article.title}
                                    </div>
                                    <p className={"fpText"} dangerouslySetInnerHTML={{__html: article.shortText}}>

                                    </p>
                                </div>
                            </div>
                        </Link>
                        :
                        <div className={"fpBloc"}>
                            <p className={"fp"}>Dernier article publié</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}