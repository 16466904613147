import axios from 'axios';
import GetToken from "./GetToken";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "accept" : "application/json",
    }
});

instance.interceptors.request.use (
    async (config) => {
        if (config.baseURL === process.env.REACT_APP_API_URL && !config.url.startsWith("login") && !config.url.startsWith("login_proginov")){
            config.headers.Authorization = `Bearer ${GetToken()}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject (error);
    }
);

export default instance;