import React, {useEffect, useState} from "react";
import "./SubCategoriesCreation.css";
import {getCategories} from "../../utils/Api";
import API from "../../utils/ApiParams";
import Preview from "../modals/Preview";
import {Redirect} from "react-router-dom";
import Loading from "../Loading";

export default function SubCategoriesCreation(props) {
    const [categories, setCategories] = useState([]);
    const [subCategoryName,setSubCategoryName] = useState("");
    const [subCategoryCat,setSubCategoryCat] = useState({});
    const [statusId,setStatusId] = useState(0);
    const [showCreated,setShowCreated] = useState(false);
    const [subCatId,setSubCatId] = useState(null);
    const [redirect,setRedirect] = useState(false);
    const [isCategoryReadOnly,setIsCategoryReadOnly] = useState(false);
    useEffect(()=>{
        async function getValidatedStatusId(){
            const response = await API.get("statuses?index=2");
            return response.data[0].id;
        }
        getCategories().then(tempCategories=>{
            setCategories(tempCategories)
            if(props.location.state){
                async function getSubCategory(id){
                    const response = await API.get("sub_categories/"+id);
                    return response.data;
                }
                setSubCatId(props.location.state.id);
                getSubCategory(props.location.state.id).then(data=>{
                    setSubCategoryCat(tempCategories.find(c=> c.id === data.category.id));
                    setSubCategoryName(data.name);
                    if(data.articles.length>0){
                        setIsCategoryReadOnly(true);
                    }
                })
            }
        });
        getValidatedStatusId().then(id=> setStatusId(id));
    }, [props.location.state])
    function handleChangeSearchCategory(e) {
        if(e.target.value){
            setSubCategoryCat(categories.find(c => c.id === parseInt(e.target.value)));
        } else setSubCategoryCat({})
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(isValid()){
            async function updateSubCat(data) {
                const response = await API.patch("sub_categories/"+subCatId,data,
                    {
                                headers:{"content-type" : "application/merge-patch+json"}
                            }
                            );
                return response;
            }
            async function createSubCat(data) {
                const response = await API.post("sub_categories",data);
                return response;
            }
            const data = {
                name: subCategoryName,
            }
            if(!isCategoryReadOnly){
                data.category = "/categories/"+subCategoryCat.id;
            }
            if(!subCatId){
                data.status = "/statuses/"+statusId;
            }
            if(subCatId){
                updateSubCat(data).then(data=>{
                    setShowCreated(true);
                    setRedirect(true);
                })
            }else{
                createSubCat(data).then(data=>{
                    setShowCreated(true);
                    setSubCategoryName("");
                })
            }
        }
    }
    function isValid() {
        if(Object.keys(subCategoryCat).length > 0 && subCategoryName.length>0)
            return true;
        return false;
    }
    function save() {
        return(
            <div>
                Votre sous-catégorie a bien été créée
            </div>
        )
    }
    if (redirect){
        return (
            <Redirect push to={"/sous-categories/liste"}/>
        )
    }
    function header() {
        let title;
        if(props.location.state){
            if (props.location.state.id) {
                title = "Édition d'une sous-catégorie";
            }
        } else{
            title = "Ajout d'une sous-catégorie";
        }
        return title;
    }
    return(
        <div>
            <h2 className={"pageTitle col-12"}>{header()}</h2>
            <form className={"subCategoriesCreation"} onSubmit={handleSubmit}>
                {showCreated &&
                <Preview isOpen={showCreated} handleClose={()=>setShowCreated(false)} showClose={false} boxWrapperClass={"savedArticle"}
                         component={save()}/>

                }
                <div className={"tile subCategoriesCreationWrapper"}>
                    <label>
                        <p>Catégorie</p>
                        {categories.length > 0 ?
                            <select disabled={isCategoryReadOnly} className={`subCatInput customSelect`} defaultValue={""}
                                    value={subCategoryCat.id} onChange={handleChangeSearchCategory}>
                                <option value="" disabled>Choisir une catégorie</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                            : <Loading height={"75px"} width={"75px"} justifyContent={"flex-start"}/>
                        }
                    </label>
                    <label>
                        <p>Sous-catégorie</p>
                        <input disabled={Object.keys(subCategoryCat).length === 0} placeholder={"Ex : Nouvelles Technologies"} className={"subCatInput"} type="text" value={subCategoryName}
                               onChange={(e)=>setSubCategoryName(e.target.value)}/>
                    </label>
                </div>
                <div className={"col-12"}>
                    <input className={"submitButton"} disabled={!isValid()} type="submit" value="Enregistrer la sous-catégorie"/>
                </div>
            </form>
        </div>
    )
}