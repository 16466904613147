import React, {useState, useEffect} from 'react';
import API from "../../utils/ApiParams";
import './Login.css'
import {Redirect, useLocation} from "react-router-dom";
import AES from "crypto-js/aes";
import HEX from "crypto-js/enc-hex";
import CryptoJS from "crypto-js";
import LogoLogin from "../../images/logoLogin.svg"

function Login({onLog}) {
    document.title = "Identification";
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [error,setError] = useState({});
    const [redirect,setRedirect] = useState(false);
    const [loading,setLoading] = useState(false);
    const search = useLocation().search;
    const loginParam = new URLSearchParams(search).get('login');
    const keyverif = new URLSearchParams(search).get('keyverif');

    useEffect(()=>{
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        let tempError = {};

        if(loginParam && keyverif){
            loginProginov().then(response=>{
                if(response.data && (response.data.token && response.data.id)){
                    document.cookie = `token=${response.data.token};path=/`;
                    onLog(response.data.role,response.data.id);
                    setRedirect(true);
                }
                setLoading(false);
            }).catch(err=>{
                tempError.log = err.response.data.error;
                setError(tempError);
                setLoading(false);
            })
        }

    },[])
    useEffect(()=>{
        if(Object.keys(error).length>0 && username){
            let tempError = {...error};
            delete tempError.log;
            delete tempError.username;
            setError(tempError);
        }
    },[username])

    useEffect(()=>{
        if(Object.keys(error).length>0 && password){
            let tempError = {...error};
            delete tempError.log;
            delete tempError.password;
            setError(tempError);
        }
    },[password])

    function handleChangeUsername(event) {
        setUsername(event.target.value);
    }

    function handleChangePassword(event) {
        setPassword(event.target.value);
    }

    async function loginProginov() {
        const response = await API.post(`login_proginov?login=${loginParam}&keyverif=${keyverif}`,{bo:true});
        return response;
    }

    async function login() {
        let key = CryptoJS.SHA256(process.env.REACT_APP_ENCRYPTION_KEY);
        key = HEX.parse(key.toString().slice(0,64));
        let iv = CryptoJS.SHA256(process.env.REACT_APP_IV);
        iv = HEX.parse(iv.toString().slice(0,32));
        let encodedPassword = AES.encrypt(password, key, {iv:iv, padding: CryptoJS.pad.ZeroPadding});
        encodedPassword = encodedPassword.ciphertext.toString(CryptoJS.enc.Base64);

        const response = await API.post("login",{login:username,password:encodedPassword,bo:true});

        return response;
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);

        let tempError = {};
        if(!username)
            tempError.username = "Nom d'utilisateur vide";
        if(!password)
            tempError.password = "Mot de passe vide";
        if(!username || !password){
            setError(tempError);
            setLoading(false);
        }
        if(username && password){
            login().then(response=>{
                if(response.data && (response.data.token && response.data.id)){
                    document.cookie = `token=${response.data.token};path=/`;
                    onLog(response.data.role,response.data.id);
                    setRedirect(true);
                }
                setLoading(false);
            }).catch(err=>{
                tempError.log = err.response.data.error;
                setError(tempError);
                setLoading(false);
            })
        }
    }

    if(redirect){
        return (<Redirect to={"/dashboard"}/>)
    }
    return (
        <div className={"loginBackground"}>
            <img className={"loginLogo"} src={LogoLogin} alt=""/>
            <div className={"loginWrapper"}>
                <div className={"loginWindow"}>
                    <form className={"loginForm"} onSubmit={handleSubmit}>
                        <input className={"loginInput"} placeholder={"Utilisateur"}  type="text" value={username} onChange={handleChangeUsername} />
                        <input className={"loginInput"} placeholder={"Mot de passe"} type="password" value={password} onChange={handleChangePassword} />
                        { Object.keys(error).length > 0 &&
                            <div className={"loginError"}>
                                {Object.keys(error).map((key,i)=>{
                                    return <div key={i}>{error[key]}</div>
                                })}
                            </div>
                        }
                        <input disabled={loading} className={"loginSubmit"} type="submit" value="CONNEXION" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
