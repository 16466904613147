import React from 'react';

import './Articles.css'
import {NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";

import ArticleList from "./ArticleList";
import ArticleCreation from "./ArticleCreation";

function Articles({reload}) {
    document.title = "Articles";
    let url = useRouteMatch().url;
    return (
        <div className={"articles"}>
            <div className={"flex"}>
                <NavLink to={`${url}/liste`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Listing des articles
                </NavLink>
                <NavLink to={`${url}/creation`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    édition d'un article
                </NavLink>
            </div>
            <Switch>
                <Route path={`${url}/liste`} render={(props) => <ArticleList {...props} reload={reload}/>} />
                <Route path={`${url}/creation`} render={(props) => <ArticleCreation {...props} reload={reload}/>}/>
                <Route>
                    <Redirect to={`${url}/liste`}/>
                </Route>
            </Switch>
        </div>
    );
}

export default Articles;
