import React, {useState, useEffect} from "react";
import "./BiggerImage.css";

export default function BiggerImage({image, unsetImage}) {
    const [bigImage,setBigImage] = useState(image);
    useEffect(()=>{
        if(Object.keys(image).length>0)
            setBigImage(image);
    },[image])

    function close() {
    setBigImage({});
    unsetImage();
    }

        if(Object.keys(bigImage).length>0)
        return(
            <div className={"bigImageWrapper"}>
                <div onClick={()=>close()} className={"bigImageFilter"}/>
                <img onClick={()=>close()} className={"bigImage"} src={bigImage.url} alt=""/>
            </div>
        );
        else return null;
}