import React, {useEffect, useState} from 'react';

import './LibraryList.css'
import Pagination from "../Pagination";
import {FilterAnd} from "../../utils/FilterList";
import {getCategories} from "../../utils/Api";
import API from "../../utils/ApiParams"
import {Redirect} from "react-router-dom";
import Confirm from "../modals/Confirm";
import BiggerImage from "../modals/BiggerImage";
import Loading from "../Loading";

function LibraryList({cols = null, rows= 3, classNames, addImage = false, imageClick = null}) {

    const [columns, setColumns] = useState(1);

    const [toLibraryEdition, setToLibraryEdition] = useState({navigate: false, id: null});

    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [filteredImages, setFilteredImages] = useState(images);
    const [shownImagesNumber, setShownImagesNumber] = useState(20);
    const [search, setSearch] = useState("");
    const [searchedFromDate, setSearchedFromDate] = useState("");
    const [searchedToDate, setSearchedToDate] = useState("");
    const [searchedCategory, setSearchedCategory] = useState("");
    const [page, setPage] = useState(1);

    const [delImageId, setDelImageId] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [bigImage, setBigImage] = useState({});
    useEffect(() => {
        setIsLoading(true);

        async function getImages() {
            const response = await API.get("media_objects?isLibrary=true&isArchived=false&order%5Bid%5D=desc");
            return response.data;
        }

        if (cols !== null)
            setColumns(cols)
        else
            setColumns(Math.floor((window.innerWidth / 324) * 90 / 100))

        getCategories().then(data => setCategories(data));
        getImages().then(data => {
            setImagesLoaded(true);
            setImages(data.map(image => (
                {
                    id: image.id,
                    name: image.name,
                    url: process.env.REACT_APP_API_URL + "images/" + image.id + "/" + image.name,
                    date: new Date(image.date),
                    title: image.title ? image.title : "",
                    category: {
                        value: image.categories.sort((a,b)=>{return a.position - b.position}).map(category => (
                            {
                                id: category.id,
                                value: category.name,
                            }
                        ))
                    }
                })))
        });
        return function () {
            setColumns(Math.floor((window.innerWidth / 324) * 90 / 100));
        }
    }, [cols]);

    useEffect(() => {
        if (categories && images) {
            if (categories.length > 0 && imagesLoaded)
                setIsLoading(false)
            else
                setIsLoading(true);
        } else
            setIsLoading(true);
    }, [categories, images]);
    useEffect(() => {
        setShownImagesNumber(columns * rows);
    }, [columns, rows]);

    function handleChangeNbImages(event) {
        if (event.target.value > 0) {
            setShownImagesNumber(event.target.value);
            handleChangePage(1);
        }
    }
    function handleChangeSearch(event) {
        setSearch(event.target.value);
    }
    function handleChangeSearchCategory(event) {
        setSearchedCategory(event.target.value);
    }
    function handleChangeSearchedFromDate(event) {
        setSearchedFromDate(event.target.value);
    }
    function handleChangeSearchedToDate(event) {
        setSearchedToDate(event.target.value);
    }
    function handleChangePage(newPage) {
        setPage(newPage);
    }
    function resetFilters() {
        setSearchedCategory("");
        setSearchedFromDate("");
        setSearchedToDate("");
        setSearch("");
    }
    useEffect(() => {
        setFilteredImages(FilterAnd(images.filter(img => {
            const tempDate = new Date(img.date);
            let valid = true;
            if (searchedFromDate && tempDate < new Date(searchedFromDate))
                valid = false;
            if (searchedToDate && tempDate > new Date(searchedToDate)) {
                valid = false;
            }
            return valid;
        }), {title: search, category: searchedCategory}));
        handleChangePage(1);
    }, [search, searchedCategory, searchedToDate, searchedFromDate, images]);

    function modifyImage(id) {
        setToLibraryEdition({navigate: true, id: id});

    }
    function deleteImage(id) {
        setOpenConfirm(true);
        setDelImageId(id);
    }

    async function confirmDeleteImage(confirm) {
        setOpenConfirm(false);
        if (confirm) {
            const response = await API.patch("media_objects/" + delImageId,
                {
                    isArchived: true
                },
                {headers: {'Content-Type': 'application/merge-patch+json'}});
            if (response.status === 200) {
                setImages(images => images.filter(img => parseInt(img.id) !== delImageId))
            }
        }
    }
    function clickImage(image) {
        if (imageClick !== null) {
            imageClick(image);
        }
    }

    if (toLibraryEdition.navigate) {
        return (
            <Redirect push to={{
                pathname: "/mediatheque/creation",
                state: {id: toLibraryEdition.id}
            }}/>
        )
    }

    return (
        isLoading ? <Loading/>
            : <div className={"flex-grow"}>
                {openConfirm &&
                <Confirm handleResponse={confirmDeleteImage}/>
                }
                <BiggerImage image={bigImage} unsetImage={() => setBigImage({})}/>

                <main className={"flex-grow"}>
                    <div className={"filterBar"}>
                        <div className={"filterNumber"}>
                            <input className={"inputNumber"} type="number" value={shownImagesNumber}
                                   onChange={handleChangeNbImages} step={columns}/>
                            <span> enregistrements par page</span>
                        </div>
                        <input className={`customInput ${search !== "" ? "activeFilter" : ""}`} placeholder={"Rechercher"}
                               type="text" value={search} onChange={handleChangeSearch}/>
                        {!addImage &&
                        <select className={`customInput customSelect ${searchedCategory !== "" ? "activeFilter" : ""}`}
                                value={searchedCategory} onChange={handleChangeSearchCategory}>
                            <option value="" disabled>Catégorie</option>
                            <option value="">Toutes</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                        }
                        {!addImage &&
                        <input className={`customInput ${searchedFromDate !== "" ? "activeFilter" : ""}`} type="date"
                               value={searchedFromDate} onChange={handleChangeSearchedFromDate}/>
                        }
                        {!addImage &&
                        <input className={`customInput ${searchedToDate !== "" ? "activeFilter" : ""}`} type="date"
                               value={searchedToDate} onChange={handleChangeSearchedToDate}/>
                        }
                        {!addImage &&
                        <button type={"button"} className={"resetFiltersButton"} onClick={resetFilters}>
                            Réinitialiser les filtres
                        </button>
                        }
                    </div>
                    <div className={"imagesList"}>
                        {filteredImages.slice((page - 1) * shownImagesNumber, (page) * shownImagesNumber)
                            .map((image) => (
                                <div key={image.id} onClick={() => clickImage(image)}
                                     className={`imageWrapper ${classNames ? classNames : ""}`}>
                                    <img onClick={() => !addImage && setBigImage(image)}
                                         className={`imageLibrary ${addImage ? "filterAdd" : "filterList"}`} src={image.url}
                                         alt=""/>
                                    {!addImage &&
                                    <div onClick={() => !addImage && setBigImage(image)} className={"imageButtons"}>
                                        <ul>
                                            <li className={"imageTitle"}>{image.title}</li>
                                            {image.category.value.map(cat => {
                                                return <li key={cat.id}
                                                           className={`imageCategory category-${cat.id}-full`}>{cat.value}</li>
                                            })}
                                            <li className={"imageDate"}>{new Date(image.date).toLocaleDateString('fr-FR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}</li>
                                        </ul>
                                        <ul>
                                            <li onClick={(e) => {
                                                e.stopPropagation();
                                                modifyImage(image.id);
                                            }} className={"imageButton actionButton modifyButton"}>Modifier
                                            </li>
                                            <li onClick={(e) => {
                                                e.stopPropagation();
                                                deleteImage(image.id);
                                            }} className={"imageButton actionButton deleteButton"}>Supprimer
                                            </li>
                                        </ul>
                                    </div>
                                    }
                                </div>
                            ))}
                    </div>
                </main>
                <div className={"flex space-between pagination"}>
                   <span>
                       {shownImagesNumber < filteredImages.length ? shownImagesNumber : filteredImages.length} enregistrements sur un total de {filteredImages.length} enregistrements
                       {/*
                       Return leftover on last page instead of shownImagesNumber
                       {shownImagesNumber < filteredImages.length
                           ? page*shownImagesNumber>filteredImages.length
                               ? shownImagesNumber-(page*shownImagesNumber-filteredImages.length)
                               : shownImagesNumber
                           : filteredImages.length} enregistrements sur un total de {filteredImages.length} enregistrements
                           */}
                   </span>
                    <Pagination currentPage={page} pageElement={shownImagesNumber} totalElements={filteredImages.length}
                                pageNeighbours={2} handleChangePage={handleChangePage}/>
                </div>
            </div>
    );
}

export default LibraryList;
