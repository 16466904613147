import React, {useEffect, useState, useRef} from 'react';
import ReactDOMServer from 'react-dom/server';

import Table from '../Table';

import './NewslettersCreation.css'
import Pagination from "../Pagination";
import {FilterAnd} from "../../utils/FilterList";
import CustomCheckbox from "../CustomCheckbox";
import {uploadFile, getDefaultImage} from "../../utils/Api"
import API from "../../utils/ApiParams";
import {Redirect} from "react-router-dom";
import LibraryList from "../library/LibraryList";
import MailHtmlGenerator from "../../utils/MailHtmlGenerator";
import Preview from "../modals/Preview";
import Confirm from "../modals/Confirm";
import axios from "axios";
import BiggerImage from "../modals/BiggerImage";
import Editor from "../customEditor/Editor";
import Loading from "../Loading";


function NewslettersCreation(props) {
    const today = new Date().toISOString().slice(0,10);
    const todayPush = new Date();
    todayPush.setHours(todayPush.getHours());

    const pushEditor = useRef(null);
    const libraryRef = useRef(null);
    const showLibraryRef = useRef(null);
    const testMailRef = useRef(null);
    const showMailRef = useRef(null);

    const [tempPushDesc,setTempPushDesc] = useState(null);
    const [oldImage,setOldImage] = useState(null);
    const [oldType, setOldType] = useState("");
    const [idCampaign,setIdCampaign] = useState(null);
    const [idOneSignal,setIdOneSignal] = useState(null);
    const [segments, setSegments] = useState([]);
    const [errors,setErrors] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const [isUploading,setIsUploading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [submitButton,setSubmitButton] = useState("");
    // 0 = Save dans la BDD, 1 = Envoie API + save BDD
    const [submitType,setSubmitType] = useState(0);

    const [newsletterStatus,setNewsletterStatus] = useState({});
    const [statuses,setStatuses] = useState([]);

    const [object, setObject] = useState("");
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [allSegmentsSelected,setAllSegmentsSelected] = useState(false);
    const [date,setDate] = useState("");
    const [isFlash, setIsFlash] = useState(false);

    const [image,setImage] = useState([]);

    const [shownArticles,setShownArticles] = useState(5);
    const [searched,setSearched] = useState("");
    const [searchPublished,setSearchPublished] = useState("");
    const [articles,setArticles] = useState([]);
    const [filteredArticles,setFilteredArticles] = useState([]);
    const [chosenArticles,setChosenArticles] = useState([]);
    const [page,setPage] = useState(1);

    const [pushTitle,setPushTitle] = useState("");
    const [pushDate,setPushDate] = useState("");
    const [pushDesc,setPushDesc] = useState({});

    const [pushShownArticles,setPushShownArticles] = useState(5);
    const [pushSearched,setPushSearched] = useState("");
    const [pushSearchPublished,setPushSearchPublished] = useState("");
    const [pushArticles,setPushArticles] = useState([]);
    const [pushFilteredArticles,setPushFilteredArticles] = useState([]);
    const [pushChosenArticle,setPushChosenArticle] = useState({});
    const [pushPage,setPushPage] = useState(1);

    const [showLibrary,setShowLibrary] = useState(false);
    const [showMail,setShowMail] = useState(false);
    const [mails, setMails] = useState("");
    const [html,setHtml] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [hasBeenPreviewed, setHasBeenPreviewed] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [coverUrl,setCoverUrl] = useState(null);
    const [showMailSent,setShowMailSent] = useState(false);
    const [showCampaignLoading, setShowCampaignLoading] = useState(false);
    const [showCampaignSent,setShowCampaignSent] = useState(false);
    const [notPreviewed, setNotPreviewed] = useState(false);
    const [showErrors,setShowErrors] = useState(false);
    const [bigImage, setBigImage] = useState({});
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    const [newsletterSaved, setNewsletterSaved] = useState(false);
    const [newsletterId, setNewsletterId] = useState(null);

    function useOutsideAlerterLibrary(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !showLibraryRef.current.contains(event.target)) {
                    setShowLibrary(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    function useOutsideAlerterMail(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !showMailRef.current.contains(event.target)) {
                    setShowMail(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerterLibrary(libraryRef);
    useOutsideAlerterMail(testMailRef);

    //Table functions
    function updateOnRowClick(id){
        setArticles(articles => articles.map(function (art) {
            if(art.id === id)
                art.choice["value"]=!art.choice["value"];
            return art;
        }))
    }
    function updatePushOnRowClick(id){
        setPushArticles(pushArticles => pushArticles.map(function (art) {
            if(art.id === id)
                art.choice["value"]=!art.choice["value"];
            else art.choice["value"]=false;
            return art;
        }))
    }

    useEffect(()=>{
        let loading = false;
        if (!articles || articles.length === 0 || !segments || segments.length === 0){
            loading = true;
        }
        if (oldType && oldType.length > 0 && oldType.toUpperCase() !== "NEWSLETTER" && !tempPushDesc){
            loading=true;
        }
        setIsLoading(loading);
    }, [articles, tempPushDesc, oldType, segments])
    useEffect(()=>{
        async function getArticles() {
            const response = await API.get("articles?isArchived=false&status.index=2&order%5BcreationDate%5D=desc&order%5Bcategories.position%5D=asc");
            return response.data;
        }
        async function getNewsletter(id){
            const response = await API.get("newsletters/"+id);
            return response.data;
        }
        async function getSegments() {
            const response = await API.get("segments");
            return response.data;
        }
        async function getStatuses() {
            const response = await API.get("statuses");
            return response.data;
        }
        getArticles().then(data =>
        {
            let tempArt = data.map(article=>(
            {
                choice : {format: "checkbox", id: article.id, value:false, tdClassName:"checkboxTable"},
                imagery: {
                    format: "img",
                    url: `${process.env.REACT_APP_API_URL}images/${article.thumbnail ? article.thumbnail.id : article.coverImage.id}/${article.thumbnail ? article.thumbnail.name : article.coverImage.name}`,
                    className: "photo",
                    value: ""
                },
                title: {format: "txt", value: article.title},
                creationDate: {format: "date", value: new Date(article.creationDate)},
                modificationDate: {format: "date", value: article.modificationDate ? new Date(article.modificationDate) : null},
                published : {format: "list",value : article.newsletters.length>0
                        ? article.newsletters.map(newsletter=>(
                            {value: newsletter.id}
                        )) : [{value: null}]},
                id:article.id,
                defaultAction: {handleClick:updateOnRowClick, id:article.id},
                categories: article.categories,
                shortText: article.shortText,
                author: article.author
            }
        ));
            let tempPushArt = data.map(article=>(
                {
                    choice : {format: "checkbox", id: article.id, value:false, tdClassName:"checkboxTable"},
                    imagery: {
                        format: "img",
                        url: `${process.env.REACT_APP_API_URL}images/${article.thumbnail ? article.thumbnail.id : article.coverImage.id}/${article.thumbnail ? article.thumbnail.name : article.coverImage.name}`,
                        className: "photo",
                        value: ""
                    },
                    title: {format: "txt", value: article.title},
                    creationDate: {format: "date", value: new Date(article.creationDate)},
                    modificationDate: {format: "date", value: article.modificationDate ? new Date(article.modificationDate) : null},
                    published : {format: "list",value : article.newsletters.length>0
                            ? article.newsletters.map(newsletter=>(
                                {value: newsletter.id}
                            )) : [{value: null}]},
                    id:article.id,
                    defaultAction: {handleClick:updatePushOnRowClick, id:article.id},
                    categories: article.categories,
                    shortText: article.shortText,
                    author: article.author
                }
            ));
            if(props.location.state){
                if (props.location.state.id){
                    if(props.location.state.action === "edit"){
                        setHasBeenSaved(true);
                    }
                    getNewsletter(props.location.state.id).then(newsletter => {
                        tempArt.map(function (art) {
                            if (newsletter.articles.some(chosenArt => chosenArt.id === art.id))
                                art.choice["value"] = true;
                            return art;
                        })
                        if(newsletter.pushArticle){
                            tempPushArt.map(function (art) {
                                if (newsletter.pushArticle.id === art.id)
                                    art.choice["value"] = true;
                                return art;
                            })
                        }
                        setNewsletterId(newsletter.id);
                        setArticles(tempArt);
                        setPushArticles(tempPushArt);
                        setOldImage(newsletter.coverImage);
                        setOldType(newsletter.format);
                        if(props.location.state.action !== "duplicate"){
                            setNewsletterStatus(newsletter.status)
                            if(newsletter.idEmailCampaign){
                                setIdCampaign(newsletter.idEmailCampaign);
                            }
                        }
                        if(newsletter.segments){
                            setSelectedSegments(newsletter.segments);
                        }

                        if(newsletter.pushDescription)
                            setTempPushDesc(newsletter.pushDescription);
                        if(newsletter.object)
                            setObject(newsletter.object);
                        if (newsletter.sendingDate)
                            setDate(newsletter.sendingDate.slice(0,10));
                        if (newsletter.isFlash)
                            setIsFlash(newsletter.isFlash);
                        if(newsletter.idOneSignal)
                            setIdOneSignal(newsletter.idOneSignal);
                        if(newsletter.pushTitle)
                            setPushTitle(newsletter.pushTitle);
                        if(newsletter.pushSendingDate){
                            const tmpDate = new Date(newsletter.pushSendingDate).toLocaleString('fr-FR', {timeZone: "Europe/Paris"});
                            const sd = tmpDate.split(/\D/);
                            const formattedDate = sd[2] + '-' + sd[1] + '-' + sd[0] + 'T' + sd[4] + ':' + sd[5] + ':' + sd[6];
                            setPushDate(formattedDate);
                        }
                    })
                }
            }else{
                getDefaultImage().then(data=> setOldImage(data));
                setArticles(tempArt);
                setPushArticles(tempPushArt);
            }
        });
        getStatuses().then(data => setStatuses(data));
        getSegments().then(data=>{
            setSegments(data);
        })
    },[props.location.state])
    useEffect(()=>{
        if(selectedSegments.length>0 && segments.length >0 && selectedSegments.length === segments.length){
            setAllSegmentsSelected(true);
        }
    }, [segments,selectedSegments])
    useEffect(() => {
        setChosenArticles(articles.filter(art=> art.choice["value"] === true));
        setFilteredArticles(FilterAnd(articles,
            {title: searched}));
        delete errors.articles;
    }, [articles]);
    useEffect(() => {
        setPushChosenArticle(pushArticles.find(art=> art.choice["value"] === true));
        setPushFilteredArticles(FilterAnd(pushArticles,
            {title: pushSearched}));
        delete errors.pushArticles;
    }, [pushArticles]);
    useEffect(() => {
        setPushFilteredArticles(FilterAnd(pushArticles.filter(pa=> pushSearchPublished === "" ? true : pushSearchPublished === "Oui" ? pa.published.value[0].value : !pa.published.value[0].value),
            {title: pushSearched}));
        setPushPage(1)
    }, [pushSearched, pushSearchPublished]);
    useEffect(()=>{
        setTimeout(()=>{
            if(Object.keys(errors).length>0){
                document.getElementById(Object.keys(errors)[0]).scrollIntoView({behavior: "smooth",block: "center", inline: "center"});
            }
        },50)
    }, [errors])
    useEffect(() => {
        setFilteredArticles(FilterAnd(articles.filter(art=> searchPublished === "" ? true : searchPublished === "Oui" ? art.published.value[0].value : !art.published.value[0].value),
            {title: searched}));
        handleChangePage(1);
    }, [searched, searchPublished]);
    useEffect(()=>{
        if(oldImage && Object.keys(oldImage).length>0)
            setCoverUrl(process.env.REACT_APP_API_URL+"images/"+oldImage.id+"/"+oldImage.name)
    },[oldImage])
    useEffect(()=>{
        if(showMailSent)
            setTimeout(()=>{
                setShowMailSent(false)
            },2000)
    },[showMailSent])
    useEffect(()=>{
        if(showCampaignSent)
            setTimeout(()=>{
                setShowCampaignSent(false)
            },2000)
    },[showCampaignSent])

    // "Complex Changes" on input change
    function handleChangeSegment(event) {
        if(parseInt(event.target.value) === 0){
            // setAllSegmentsSelected(true);
            setSelectedSegments([]);
            segments.map(segment=>{
                setSelectedSegments(selectedSegments =>
                    [...selectedSegments, segment]);
            })
        }
        else if(!selectedSegments.some(sc=>sc.id === parseInt(event.target.value)))
            setSelectedSegments(selectedSegments =>
                [...selectedSegments, segments.find(s => s.id === parseInt(event.target.value))]);
        delete errors.segment;
    }
    function removeSegment(segment) {
        setSelectedSegments(selectedSegments.filter(s => s !== segment));
    }
    function removeAllSegments() {
        setAllSegmentsSelected(false)
        if(props.location.state)
            setSelectedSegments([])
    }
    function handleChangeImage(event){
        if(event.target.files.length>0){
            setImage(event.target.files)
            setOldImage(null);
            setCoverUrl( URL.createObjectURL(event.target.files[0]));
            delete errors.image;
        }
    }
    function handleChangeLibraryImage(libraryImage){
        setOldImage({id: libraryImage.id, name: libraryImage.name});
        setImage([]);
        setShowLibrary(false);
        delete errors.image;
    }
    function handleChangePage(newPage){
        setPage(newPage);
    }
    function handleChangeShownArticles(event) {
        if(event.target.value>0)
            setShownArticles(event.target.value);
    }
    function handleChangePushDesc(pushDesc) {
        setPushDesc(pushDesc);
        delete errors.pushDesc;
    }
    async function handleSubmit(event) {
        let errorAPI = false;
        if(event)
            event.preventDefault();

        if(isFormValid()){
            setIsUploading(true);
            async function uploadEditor(){
                await Promise.all([pushEditor.current.uploadImages()]);
            }

            async function uploadNewsletter(data) {
                const response = await API.post("newsletters",data);
                return response.data;
            }

            async function updateNewsletter(data){
                const response = await API.patch("newsletters/"+newsletterId, data,
                    {
                        headers:{"content-type" : "application/merge-patch+json"}
                    })
                return response.data;
            }

            async function createSendinBlueCampaign(){
                setShowCampaignLoading(true);
                let response;
                let url = 'https://api.sendinblue.com/v3/emailCampaigns';
                if (idCampaign) {
                    url += "/" + idCampaign;
                }
                let finalDate;
                let dateAddHours;

                if(!isFlash){
                    if(new Date(date) < Date.now()){
                        dateAddHours=new Date();
                        dateAddHours.setHours(dateAddHours.getHours()+1);
                        finalDate = dateAddHours.toISOString()
                    } else{
                        dateAddHours=new Date(date);
                        dateAddHours.setHours(dateAddHours.getHours()+1);
                        finalDate = dateAddHours.toISOString();
                    }
                } else{
                    dateAddHours=new Date(Date.now());
                    dateAddHours.setHours(dateAddHours.getHours()+1);
                    finalDate = dateAddHours.toISOString()
                }

                let sendingSegments;
                // if (allSegmentsSelected){
                //     sendingSegments = segments.filter(function(s) {
                //         if (s.idSegmentSendinBlue === undefined) {
                //             return false;
                //         }
                //         return true;
                //     }).map(function(s) { return s.idSegmentSendinBlue; });
                // } else {

                sendingSegments = selectedSegments.filter(function(s) {
                    if (s.idSegmentSendinBlue === undefined) {
                        return false;
                    }
                    return true;
                }).map(function(s) { return s.idSegmentSendinBlue; });


                    // sendingSegments = selectedSegments.map(s => s.idSegmentSendinBlue);
                // }

                const data = {
                    sender: {name: 'Distrinews', email: 'newsletter@distriboissons.com'},
                    name: `${object}`,
                    scheduledAt: `${finalDate}`,
                    subject: `${isFlash ? '[FLASH] ' : ''} ${object}`,
                    recipients: {listIds: sendingSegments},
                    replyTo: 'newsletter@distriboissons.com',
                    htmlContent: `${ReactDOMServer.renderToStaticMarkup(MailHtmlGenerator(chosenArticles.map(art =>
                        ({
                            id: art["id"],
                            title: art["title"].value,
                            categories: art["categories"],
                            coverUrl: art["imagery"].url,
                            text: art["shortText"],
                            author: art["author"].name
                        })
                    ),process.env.REACT_APP_API_URL+"images/"+oldImage.id+"/"+oldImage.name, isFlash ))}`,
                }

                const options = {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': `${process.env.REACT_APP_SENDINBLUE_KEY}`
                    },
                };
                if (idCampaign) {
                    response = await axios.put(url, data, options)
                        .catch(err=>{
                            setIsUploading(false);
                            setShowCampaignLoading(false);
                        });
                } else {
                    response = await axios.post(url,data,options)
                        .catch(err=>{
                        setIsUploading(false);
                        setShowCampaignLoading(false);
                    });
                }
                setShowCampaignLoading(false);
                return response;
            }

            async function sendPushNotification(){

                let tempDivElement = document.createElement("div");
                tempDivElement.innerHTML = pushDesc;
                const content = tempDivElement.textContent || tempDivElement.innerText || "";
                const url = "https://onesignal.com/api/v1/notifications";
                const options = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Basic ${process.env.REACT_APP_ONESIGNAL_KEY}`,
                    }
                };

                let pushDateConvert=new Date(pushDate);

                const data = {
                    app_id : `${process.env.REACT_APP_ONESIGNAL_APP_ID}`,
                    headings: {"en": `${pushTitle}`},
                    contents: {"en": `${content}`},
                    included_segments: ["Subscribed Users"],
                    data: {article : pushChosenArticle.id},
                    send_after: `${new Date(pushDateConvert.getTime()+(30*60000)).toString()}`
                };

                return await axios.post(url,data, options);
            }
            async function cancelPushNotification(){
                const url = `https://onesignal.com/api/v1/notifications/${idOneSignal}?app_id=${process.env.REACT_APP_ONESIGNAL_APP_ID}`;
                const options = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Basic ${process.env.REACT_APP_ONESIGNAL_KEY}`,
                    }
                };

                const response = axios.delete(url,options);
                return response;
            }
            let newsletter = {};
            if(submitButton === "newsletter" || submitButton === "both"){
                newsletter.object = object;
                if (isFlash){
                    newsletter.isFlash = isFlash;
                    newsletter.sendingDate = new Date(Date.now());
                }
                else{
                    newsletter.sendingDate = date !== "" ? new Date(date) : new Date();
                }
                // if (allSegmentsSelected){
                //     newsletter.segments = segments.map(s => "segments/"+s.id);
                // } else{
                    newsletter.segments = selectedSegments.map(s => "segments/"+s.id);
                // }
                newsletter.articles = chosenArticles.map(art =>(
                    "/articles/"+art.id
                ));
            }
            if(submitButton === "push" || submitButton === "both"){
                newsletter.pushTitle = pushTitle;
                newsletter.pushSendingDate = new Date(pushDate);
                if (pushChosenArticle && Object.keys(pushChosenArticle).length > 0)
                    newsletter.pushArticle = "/articles/"+pushChosenArticle.id;
                else newsletter.pushArticle = null;
            }
            if(submitButton === "newsletter"){
                newsletter.format = "Newsletter";
                newsletter.pushTitle = "";

                newsletter.pushSendingDate = null;
                newsletter.pushArticle = null;
            }
            else if (submitButton === "push")
                newsletter.format = "Push";
            else newsletter.format = "Les 2";

            if (image.length>0){
                await uploadFile(image[0],"media_objects").then(data =>{
                    newsletter.coverImage  = "/media_objects/"+data;
                    setCoverUrl(process.env.REACT_APP_API_URL+"images/"+data+"/"+image[0].name);

                })
            }
            else if (oldImage !== null){
                setCoverUrl(process.env.REACT_APP_API_URL+"images/"+oldImage.id+"/"+oldImage.name);
                newsletter.coverImage = "/media_objects/"+oldImage.id;
            }
            await uploadEditor()

            if (submitType === 0){
                if(!newsletterStatus.index)
                    newsletter.status = "/statuses/"+statuses.find(s => s.index === 0).id;
            } else{
                if (!newsletterStatus || newsletterStatus.index !== 2) {
                    newsletter.status = "/statuses/" + statuses.find(s => s.index === 1).id;
                    if (submitButton === "newsletter") {
                        await createSendinBlueCampaign().then(response=>{
                            if(response){
                                if(response.status === 201){
                                    newsletter.idEmailCampaign = response.data.id;
                                    setShowCampaignSent(true)
                                } else if (response.status === 204){
                                    setShowCampaignSent(true)
                                }
                            } else{
                                setIsUploading(false);
                                setShowCampaignLoading(false);
                                errorAPI = true;
                            }
                        })
                    }
                    //TODO Refactor this part
                    if (submitButton === "push") {
                        if (new Date(newsletter.pushSendingDate) > Date.now()) {
                            if (idOneSignal) {
                                await cancelPushNotification()
                            }
                            await sendPushNotification().then(response => {
                                if (response && response.status === 200 && response.data.id) {
                                    setIdOneSignal(response.data.id);
                                    newsletter.idOneSignal = response.data.id;
                                } else {
                                    setIsUploading(false);
                                }
                            })
                        }
                    }
                    if(submitButton === "both"){

                        await createSendinBlueCampaign().then(response=>{
                            if(response.status === 201){
                                newsletter.idEmailCampaign = response.data.id;
                            } else if (response.status === 204){
                                setShowCampaignSent(true)
                            }else{
                                setIsUploading(false);
                                setShowCampaignLoading(false);
                                errorAPI = true;
                                //TODO Add error
                            }


                        });

                        // if(new Date(newsletter.pushSendingDate) > Date.now() && isUploading){

                            if (idOneSignal ){
                                await cancelPushNotification()
                            }

                            await sendPushNotification().then(response=>{
                                if(response && response.status === 200 && response.data.id){
                                    setIdOneSignal(response.data.id);
                                    newsletter.idOneSignal = response.data.id;
                                }else{
                                    setIsUploading(false);
                                }
                            })
                        // }
                    }
                }
            }

            if(submitButton === "push" || submitButton === "both")
                newsletter.pushDescription = pushDesc;
            else{
                newsletter.pushDescription = "";
            }
            if(!errorAPI){
                if((props.location.state && props.location.state.action === "edit" ) || hasBeenSaved){
                        await updateNewsletter(newsletter).then(data => {
                            setIsUploading(false);
                            setHasBeenSaved(true);

                            if (submitType !== 0){
                                setRedirect(true);
                            }else {
                                setNewsletterSaved(true);
                            }
                        }).catch(err=>{
                            setIsUploading(false);
                        });
                        return true;
                }
                if ((!props.location.state || props.location.state.action !== "edit" ) && !hasBeenSaved){
                    await uploadNewsletter(newsletter).then(data => {
                        setIsUploading(false);
                        setHasBeenSaved(true);
                        setNewsletterId(data.id);
                        setNewsletterStatus(data.status);
                        if (submitType !== 0){
                            setRedirect(true);
                        }else {
                            setNewsletterSaved(true);
                        }
                    }).catch(err => {
                        setIsUploading(false);
                    });
                }
            }

        }else{
            setIsUploading(false);
        }

    }

    //Form validation
    function isFormValid(fromSendTest=false) {
        let isValid = true;
        let tempErrors = {};
        if(image.length === 0 && oldImage === null){
            isValid=false;
            tempErrors.image = "Il n'y a pas de visuel.";
        }

        if(submitButton === "newsletter" || submitButton === "both" || fromSendTest) {
            if (object.length === 0) {
                isValid = false;
                tempErrors.object = "Le champ objet est vide.";
            }
            if (selectedSegments.length === 0) {
                isValid = false;
                tempErrors.segment = "Le champ catégorie des destinataires est vide.";
            }
            if (date.length === 0 && !isFlash) {
                isValid = false;
                tempErrors.date = "Le champ date d'envoi est vide ou le champ flash n'est pas coché.";
            }
            if(chosenArticles.length === 0){
                isValid=false;
                tempErrors.articles = "Il n'y a aucun article choisi.";
            }
            if(!hasBeenPreviewed && (!newsletterStatus.index || newsletterStatus.index === 0 || newsletterStatus.index === 1)){
                isValid=false;
                tempErrors.preview = "La newsletter n'a pas été prévisualisée";
                setNotPreviewed(true);
            }
        }

        if(submitButton === "push" || submitButton === "both") {
            if (pushTitle.length === 0) {
                isValid = false;
                tempErrors.pushTitle = "Le champ titre du push est vide.";
            }
            if (pushDate.length === 0) {
                isValid = false;
                tempErrors.pushDate = "Le champ date du push est vide.";
            }
            if (!pushDesc || pushDesc.length === 0) {
                isValid = false;
                tempErrors.pushDesc = "Le champ description du push est vide.";
            }
            if(!pushChosenArticle || Object.keys(pushChosenArticle).length === 0){
                isValid=false;
                tempErrors.pushArticles = "Il n'y a aucun article choisi.";
            }
        }
        setErrors(tempErrors);
        if(Object.keys(tempErrors).length>0){
            setShowErrors(true);
        }
        return isValid;
    }


    function sendTestMail() {
        if (isFormValid(true)) {
            const url = 'https://api.sendinblue.com/v3/smtp/email';
            const tempHtml= MailHtmlGenerator(chosenArticles.map(art=>
                ({
                    id: art["id"],
                    title: art["title"].value,
                    categories: art["categories"],
                    coverUrl: art["imagery"].url,
                    text: art["shortText"],
                    author: art["author"].name
                })
            ),coverUrl, isFlash);
            setHtml(tempHtml)

            const options = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'api-key': `${process.env.REACT_APP_SENDINBLUE_KEY}`
                },
                body: JSON.stringify({
                    sender: {name: 'Distrinews', email: 'newsletter@distriboissons.com'},
                    subject: `${isFlash ? '[FLASH] ' : ''} ${object}`,
                    to: mails.split(';').map(mail=>{
                        return {email: `${mail}`};
                    }),
                    replyTo: {email: 'newsletter@distriboissons.com'},
                    htmlContent: `${ReactDOMServer.renderToStaticMarkup(tempHtml)}`
                })
            };

            fetch(url, options)
                .then(res => res.json())
                .then(json => {
                    setShowMail(false);
                    setShowMailSent(true);
                })
                .catch(err => console.error('error:' + err));
        }
    }

    //Disabling field functions
    function isNewsletterDateInvalid() {
        if(idCampaign && date && (new Date(date) <= new Date(Date.now())) && props.location.state.action !== "duplicate")
            return true;
        else
            return false;
    }
    //TODO Add condition related to oneSignalId
    function isPushDateInvalid() {
        if (props.location.state) {
            if (props.location.state.action === "read"){
                return true;
            }
            if (pushDate && newsletterStatus === 2 && (new Date(pushDate) <= new Date(Date.now()))){
                return true;
            }
        }
        return false;
    }
    function isReadOnly() {
        if (props.location.state) {
            if (props.location.state.action === "read"){
                return true;
            }
        }
        return false;
    }

    //Rendering functions
    function showError(field,errorStyle=true, noId=false) {
        if (errors[field]){
            return (
                <div className={`${errorStyle?"error":""}`} id={!noId?field:""}>
                    {errors[field]}
                </div>
            )
        }
        else return null;
    }
    function displayErrors() {
        let items = [];
        for (let key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                items.push(key);
            }
        }
        return(
            <>
                {items.map(item=>{
                    return(
                        showError(item,false, true)
                    )
                })}
            </>
        )
    }
    function loading(){
        return(
            <div className={"loading"}>Chargement...</div>
        );
    }
    function header() {
        let title;
        if(props.location.state){
            if (props.location.state.action === "edit") {
                title = "Édition d'une newsletter";
            }
            else if (props.location.state.action === "read") {
                title = "Lecture d'une newsletter";
            }
            else {
                title = "Création d'une newsletter";
            }
        } else{
            title = "Création d'une newsletter";
        }
        return title;
    }
    function mailSent() {
        return(
            <div>
                Votre mail a bien été envoyé
            </div>
        )
    }
    function newsletterSavedDisplay() {
        return(
            <div>
                {
                    isNewsletter() && isPush()
                        ? 'Votre newsletter et votre push ont bien été enregistrés'

                        :
                            isNewsletter()
                                ? 'Votre newsletter a bien été enregistrée'

                                : 'Votre push a bien été enregistré'

                }
            </div>
        )
    }
    function CampaignSent() {
        return(
            <div>
                Votre campagne a bien été envoyé
            </div>
        )
    }

    function isNewsletter() {
        if(object.length>0 || selectedSegments.length>0
            || date.length>0 || isFlash || chosenArticles.length > 0)
            return true;
        else return false;
    }
    function isPush() {
        if(pushTitle.length>0 || pushDate.length>0 || (pushChosenArticle && Object.keys(pushChosenArticle).length > 0)
            || pushDesc.length>0)
            return true;
        else return false;
    }

    function displayButtons(){

        return(
            <div className={"col-12 newsletterCreationButtons"}>
                {isNewsletter() &&
                <div className={"flex"}>
                    <button type="button" className={"newsletterCreationButton previewNewsletterButton"}
                            onClick={()=>{
                                setHtml(MailHtmlGenerator(chosenArticles.map(art=>
                                    ({
                                        id: art["id"],
                                        title: art["title"].value,
                                        categories: art["categories"],
                                        coverUrl: art["imagery"].url,
                                        text: art["shortText"],
                                        author: art["author"].name
                                    })
                                ), coverUrl, isFlash));
                                setShowPreview(!showPreview);
                                setHasBeenPreviewed(true);
                                delete errors.preview;
                            }}>
                        Prévisualiser la newsletter
                    </button>
                    <div style={{position: "relative"}}>
                        {showMail &&
                        <label ref={testMailRef} className={"mailWindow tile"}>
                            <div className={"mailWindowLabel"}>
                                Renseignez les adresses mails
                            </div>
                            <input className={"newsletterInput mailWindowInput"} type="text" value={mails}
                                   onChange={(e) => setMails(e.target.value)}/>
                            <button type={"button"} onClick={(e)=>{e.stopPropagation();sendTestMail()}}
                                    className={"newsletterCreationButton submitNewsletterButton mainWindowButton"}>
                                Envoyer
                            </button>
                        </label>
                        }
                        <button ref={showMailRef} type="button" className={"newsletterCreationButton submitNewsletterButton greyButton"}
                                onClick={()=>setShowMail(!showMail)}>
                            Envoyer un mail de test
                        </button>
                    </div>
                </div>
                }

                {(isNewsletter() && isPush())
                    ? <div>
                        {(!newsletterStatus.index ||newsletterStatus.index === 0) &&
                        <input disabled={isUploading} className={"newsletterCreationButton submitNewsletterButton validationButton"}
                               type="submit"
                               value={`${newsletterStatus.index !== 0?"Créer":"Modifier"} les deux`}
                               onClick={() => {
                                   setSubmitButton("both");
                                   setSubmitType(0);
                               }}/>
                        }
                        <button disabled={isUploading || !hasBeenSaved} className={"newsletterCreationButton submitNewsletterButton"}
                                type="button"
                                onClick={() => {
                                    setSubmitButton("both");
                                    setSubmitType(1);
                                    setShowConfirm(true);
                                }}>
                            Envoyer les deux
                        </button>
                    </div>
                    : isNewsletter()
                        ? <div>
                            {(!newsletterStatus.index || newsletterStatus.index === 0) &&
                            <input disabled={isUploading} className={"newsletterCreationButton submitNewsletterButton validationButton"}
                                   type="submit"
                                   value={`${newsletterStatus.index !== 0?"Créer":"Modifier"} la newsletter`}
                                   onClick={() => {
                                       setSubmitButton("newsletter");
                                       setSubmitType(0);
                                   }}/>
                            }
                            <button disabled={isUploading || !hasBeenSaved} className={"newsletterCreationButton submitNewsletterButton"}
                                    type="button"
                                    onClick={() => {
                                        setSubmitButton("newsletter");
                                        setSubmitType(1);
                                        setShowConfirm(true);
                                    }}>
                                Envoyer la newsletter
                            </button>
                        </div>

                        : isPush()
                            ? <div>
                                {(!newsletterStatus.index || newsletterStatus.index === 0) &&
                                <input disabled={isUploading}
                                       className={"newsletterCreationButton submitNewsletterButton validationButton"}
                                       type="submit"
                                       value={`${newsletterStatus.index !== 0?"Créer":"Modifier"} le push`}
                                       onClick={() => {
                                           setSubmitButton("push");
                                           setSubmitType(0);
                                       }}/>
                                }
                                <button disabled={isUploading || !hasBeenSaved} className={"newsletterCreationButton submitNewsletterButton"}
                                        type="button"
                                        onClick={() => {
                                            setSubmitButton("push");
                                            setSubmitType(1);
                                            setShowConfirm(true);
                                        }}>
                                    Envoyer le push
                                </button>
                            </div>
                            : ""
                }
            </div>
        )
    }
    function showBigImage() {
        if(oldImage){
            setBigImage({url: process.env.REACT_APP_API_URL+"images/"+oldImage.id+"/"+oldImage.name});
        }else{
            setBigImage({url:URL.createObjectURL(image[0])});
        }
    }

    if(redirect){
        return (<Redirect to={"/newsletters/liste"}/>)
    }

    return (
        <div className={"newsletterCreation"}>
            {showMailSent &&
            <Preview showClose={false} handleClose={() => setShowMailSent(false)} isOpen={showMailSent}
                     component={mailSent()} boxWrapperClass={"mailSent"}
            />
            }
            {newsletterSaved &&
            <Preview showClose={false} handleClose={() => setNewsletterSaved(false)} isOpen={newsletterSaved}
                     component={newsletterSavedDisplay()} boxWrapperClass={"mailSent"}
            />
            }
            {showCampaignLoading &&
            <Preview showClose={false} handleClose={()=>setShowCampaignLoading(false)} isOpen={showCampaignLoading}
                     component={<Loading/>} boxWrapperClass={"loading"} manualClosing={false}/>
            }
            {showCampaignSent &&
            <Preview showClose={false} handleClose={() => setShowCampaignSent(false)} isOpen={showCampaignSent}
                     component={CampaignSent()} boxWrapperClass={"mailSent"}
            />
            }
            {showErrors &&
            <Preview isOpen={showErrors} handleClose={()=>setShowErrors(false)} boxWrapperClass={"warningModal"}
                     component={displayErrors()} showClose={false}/>
            }
            <BiggerImage image={bigImage} unsetImage={()=>setBigImage({})}/>
            {showConfirm &&
            <Confirm text={"Voulez-vous envoyer ?"} handleResponse={(result)=>{
                if(result){
                    handleSubmit();
                    setShowConfirm(false)
                }else{
                    setShowConfirm(false)
                }
            }}/>
            }
            {(html && showPreview) &&
            <Preview component={
                <iframe title={"Mail Preview"} srcDoc={ReactDOMServer.renderToStaticMarkup(html)}
                        className={"previewContent"}> </iframe>
            } boxWrapperClass={"previewMail"} isOpen={showPreview} handleClose={()=> setShowPreview(false)} iframe={true}/>
            }
            <form  onSubmit={handleSubmit}>
                <h2 className={"pageTitle col-12"}>{header()}</h2>
                <div className={"col-12 flex blocWrapper"}>
                    <div className="col-7 bloc tile">
                        <p className={"blocTitle"}>
                            Informations générales
                        </p>
                        <label>
                            Objet de la newsletter
                            <input placeholder={"Ex : Informations de la semaine"} disabled={isNewsletterDateInvalid() || isReadOnly()} className={"newsletterInput"} type="text" value={object}
                                   onChange={(e)=>{
                                       setObject(e.target.value);
                                       delete errors.object;
                                   }}/>
                            {showError("object")}
                        </label>
                        <label>
                            <p>Catégorie de destinataires</p>
                            {segments.length > 0 ?
                                <div>
                                    <select disabled={isNewsletterDateInvalid() || isReadOnly()}
                                            className={"newsletterInput customSelect"} value={""}
                                            onChange={handleChangeSegment}>
                                        <option value="" disabled>Ajouter une catégorie</option>
                                        {/*{!allSegmentsSelected &&*/}
                                        <option value={0}>TOUT</option>
                                        {/*}*/}
                                        {segments.map((segment) => !selectedSegments.some(s => s.id === segment.id)
                                            ?
                                            segment.idSegmentSendinBlue &&
                                                <option key={segment.id} value={segment.id}>{segment.name}</option>
                                            : ""
                                        )}
                                    </select>
                                    {selectedSegments.map((selectedSegment) => (
                                        <button disabled={isNewsletterDateInvalid() || isReadOnly()}
                                                onClick={() => removeSegment(selectedSegment)}
                                                className={"subCategoryButton"}
                                                key={selectedSegment.id}>{selectedSegment.name} <span
                                            className={"delete"}>✕</span></button>
                                    ))}
                                    {/*{allSegmentsSelected &&*/}
                                    {/*<button type={"button"} disabled={isNewsletterDateInvalid() || isReadOnly()}*/}
                                    {/*        onClick={() => removeAllSegments()}*/}
                                    {/*        className={"subCategoryButton"}*/}
                                    {/*>TOUT <span className={"delete"}>✕</span></button>*/}
                                    {/*}*/}
                                    {showError("segment")}
                                </div>
                                : <Loading height={"75px"} width={"75px"} justifyContent={"flex-start"}/>
                            }
                        </label>
                        <label>
                            Date d'envoi
                            <input disabled={isNewsletterDateInvalid() || isReadOnly()} className={"newsletterInput dateInput"} type="date" value={date} min={today}
                                   onChange={(e)=>{
                                       setDate(e.target.value);
                                       delete errors.date;
                                   }}/>
                            <div className={"newsletterCheckbox"}>
                                <CustomCheckbox onChange={(e)=>{
                                    setIsFlash(e.target.checked);
                                    delete errors.date;
                                }} disabled={isNewsletterDateInvalid() || isReadOnly()} checked={isFlash}/>
                                <span className={"flashNewsletter"}>Newsletter flash</span>
                            </div>
                            {showError("date")}
                        </label>
                    </div>


                    <div className="col bloc tile imageCover">
                        <p className={"blocTitle"}>Image de couverture</p>
                        {(image.length > 0 || oldImage !== null) &&
                        <img onClick={()=> showBigImage()} className={"image"}
                             src={image.length > 0
                                 ? URL.createObjectURL(image[0])
                                 : process.env.REACT_APP_API_URL+"images/"+oldImage.id+"/"+oldImage.name} alt=""/>
                        }
                        {showError("image")}
                        <div className={"col-12 flex space-between visual"}>
                            <label ref={showLibraryRef} className={"buttonImageInput"} onClick={()=>!isReadOnly() && setShowLibrary(!showLibrary)}>
                                Sélectionner un visuel depuis la médiathèque<span className={"add"}> +</span>
                            </label>
                            <label className={"buttonImageInput"}>
                                Sélectionner un visuel depuis mon ordinateur<span className={"add"}> +</span>
                                <input disabled={isReadOnly()} className={"imageInput"} type="file" accept="image/*" onChange={handleChangeImage}/>
                            </label>
                        </div>
                        <div className={`libraryDisplayWrapper ${showLibrary && "showLibrary"}`}>
                        </div>
                        <div ref={libraryRef} className={`libraryDisplay ${showLibrary && "showLibrary"}`}>
                            <LibraryList classNames={"resizedImageWrapper"} addImage={true} imageClick={handleChangeLibraryImage} cols={Math.floor((window.innerWidth/324)*90/100) >=4 ? 4 : 3} rows={2}/>
                        </div>

                    </div>


                    <div className="col-12 bloc tile">
                        <p className={"blocTitle"}>Articles sélectionnés pour la newsletter</p>
                        {articles.length> 0 ?
                        <div>
                            <div className={"filterBar"}>
                                <div className={"filterNumber"}>
                                    <input className={"inputNumber"} type="number" value={shownArticles}
                                           onChange={handleChangeShownArticles}/>
                                    <span> enregistrements par page</span>
                                </div>
                                <input className={`customInput ${searched !== "" ? "activeFilter" : ""}`}
                                       placeholder={"Rechercher"} type="text" value={searched}
                                       onChange={((e)=>{
                                                    setSearched(e.target.value);
                                                })}
                                />
                                <select className={`customInput customSelect ${searchPublished !== "" ? "activeFilter" : ""}`}
                                        value={searchPublished} onChange={(e)=>{
                                            setSearchPublished(e.target.value)}}>
                                    <option value="" disabled>Parution</option>
                                    <option value="">Tout</option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                </select>
                            </div>
                            {showError("articles")}
                            <Table elementsPerPage={shownArticles}
                                   currentPage={page}
                                   elements={filteredArticles}
                                   headers={{
                                       choice: "Choix",
                                       imagery: "Visuel",
                                       title: "Titre",
                                       creationDate: "Date de création",
                                       modificationDate: "Date de modification",
                                       published: "Déjà paru dans"
                                   }}
                                    readOnly={isNewsletterDateInvalid() || isReadOnly()}
                            />
                            <div className={"flex space-between pagination"}>
                           <span>
                               {shownArticles < filteredArticles.length ? shownArticles : filteredArticles.length} enregistrements sur un total de {filteredArticles.length} enregistrements
                           </span>
                                <Pagination currentPage={page} pageElement={shownArticles}
                                            totalElements={filteredArticles.length} pageNeighbours={2}
                                            handleChangePage={handleChangePage}/>
                            </div>
                        </div>
                            :<Loading/>
                            }
                    </div>


                    <h2 className={"pageTitle col-12"}>Envoi d'une notification push</h2>
                    <div className="col-7 bloc tile" style={{backgroundColor:"#fffee9"}}>
                        <p className={"blocTitle"}>Informations générales</p>
                        <label>
                            Titre de la notification
                            <input placeholder={"Ex : Informations de la semaine"} disabled={isPushDateInvalid() || isReadOnly()} className={"newsletterInput"} type="text" value={pushTitle}
                                   onChange={(e)=>{
                                        setPushTitle(e.target.value)
                                        delete errors.pushTitle;
                                   }}/>
                            {showError("pushTitle")}
                        </label>
                        <label>
                            Date d'envoi
                            <input disabled={isPushDateInvalid() || isReadOnly()} className={"newsletterInput dateInput"} type="datetime-local" value={pushDate} min={todayPush.toISOString().slice(0,16)}
                                   onChange={(e)=>{
                                        setPushDate(e.target.value)
                                        delete errors.pushDate;
                                   }}/>
                            {showError("pushDate")}
                        </label>
                        <label style={{marginBottom:"8px"}}>
                            Description
                        </label>
                        <div className={"editorArea"}>
                            {isLoading
                                ? loading()
                                :
                                <Editor ref={pushEditor} placeholder={"140 caractères maximum"} handleChange={handleChangePushDesc}
                                oldContent={tempPushDesc} maxChars={140} options={3} height={200}
                                        readOnly={isPushDateInvalid() || isReadOnly()}/>
                            }
                        </div>
                        {showError("pushDesc")}
                    </div>

                    <div className="col-12 bloc tile" style={{backgroundColor:"#fffee9"}}>
                        <p className={"blocTitle"}>Article sélectionné pour la notification</p>
                        {pushArticles.length > 0 ?
                            <div>
                                <div className={"filterBar"}>
                                    <div className={"filterNumber"}>
                                        <input className={"inputNumber"} type="number" value={pushShownArticles}
                                               onChange={(e) => {
                                                   setPushShownArticles(e.target.value);
                                                   setPushPage(1);
                                               }}/>
                                        <span> enregistrements par page</span>
                                    </div>
                                    <input className={`customInput ${pushSearched !== "" ? "activeFilter" : ""}`}
                                           placeholder={"Rechercher"} type="text" value={pushSearched}
                                           onChange={(e) => setPushSearched(e.target.value)}/>
                                    <select
                                        className={`customInput customSelect ${pushSearchPublished !== "" ? "activeFilter" : ""}`}
                                        value={pushSearchPublished}
                                        onChange={(e) => setPushSearchPublished(e.target.value)}>
                                        <option value="" disabled>Parution</option>
                                        <option value="">Tout</option>
                                        <option value="Oui">Oui</option>
                                        <option value="Non">Non</option>
                                    </select>
                                </div>
                                {showError("pushArticles")}
                                <Table elementsPerPage={pushShownArticles}
                                       currentPage={pushPage}
                                       elements={pushFilteredArticles}
                                       headers={{
                                           choice: "Choix",
                                           imagery: "Visuel",
                                           title: "Titre",
                                           creationDate: "Date de création",
                                           modificationDate: "Date de modification",
                                           published: "Déjà paru dans"
                                       }}
                                       readOnly={isPushDateInvalid() || isReadOnly()}
                                />
                                <div className={"flex space-between pagination"}>
                           <span>
                               {pushShownArticles < pushFilteredArticles.length
                                   ? pushShownArticles
                                   : pushFilteredArticles.length} enregistrements sur un total de {pushFilteredArticles.length} enregistrements
                           </span>
                                    <Pagination currentPage={pushPage} pageElement={pushShownArticles}
                                                totalElements={pushFilteredArticles.length} pageNeighbours={2}
                                                handleChangePage={(newPage) => setPushPage(newPage)}/>
                                </div>
                            </div>
                            : <Loading/>
                        }
                    </div>
                    {(!isLoading && !isReadOnly()) &&
                        <>
                            {showError("preview")}
                            {displayButtons()}
                        </>
                    }
                </div>
            </form>

        </div>
    );
}

export default NewslettersCreation;
