import React, {useContext, useEffect, useState} from "react";
import { AnalyticsDashboard,PageViewsPerPathChart,  SessionsByDeviceCategoryChart, SessionsByDateChart, SessionsBySourceChart } from "react-analytics-charts";
import "./DashboardNewsletters.css";

export default function DashboardAnalytics() {
    const [fromDate,setFromDate] = useState(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0,10));
    const [toDate,setToDate] = useState(new Date().toISOString().slice(0,10));

    return (
        <div className={"col-10"}>
            <div className={"filterBar"}>
                <label>
                    <span className={"dashboardFilterText"}>Date de début</span>
                    <input className={`customInput`}
                           type="date" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
                </label>
                <label>
                    <span style={{marginLeft:"2rem"}} className={"dashboardFilterText"}>Date de fin</span>
                    <input className={`customInput`}
                           type="date" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
                </label>
                <button className={[`customInput`, 'gaButton'].join(' ')} onClick={()=>window.open("https://analytics.google.com/analytics/web/?authuser=2#/report-home/a171783100w269495584p241402282", "_blank")}>Google Analytics</button>
            </div>

            <AnalyticsDashboard
                authOptions={{ clientId:`${process.env.REACT_APP_GA_ID}` }}
                renderCharts={(gapi, viewId) => {
                    return (
                        <div className={"flex"} style={{justifyContent:"space-evenly"}}>
                            <div className={"col-6 tile dashboardTile"}>
                                <p className={"chartTitle"}>Nb de sessions utilisateurs - Nb pages vues</p>
                                <div className={"flex flex-center"}>
                                    <div className={"col-10"}>
                                        <SessionsByDateChart
                                            gapi={gapi}
                                            viewId={viewId}
                                            showPageViews
                                            query={{
                                                "start-date": fromDate,
                                                "end-date": toDate,
                                            }}
                                            height={'100%'}
                                            options={{
                                                title:'',
                                                animation: {
                                                    duration: 1000,
                                                    easing: 'out',
                                                },
                                                pointSize: 5,
                                                lineWidth: 2,
                                                isStacked: true,
                                                legend: {position: 'top', maxLines: 2},
                                                tooltip: { isHtml: true, trigger: "visible" },
                                                series: {
                                                    0: {color: '#DC052C'},
                                                    1: {color: '#2505dc'},
                                                },
                                                hAxis: {gridlines: {multiple: 1},
                                                    format: 'd/MM/yy',
                                                    },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-5 tile dashboardTile"}>
                                <p className={"chartTitle"}>Pages les plus consultées</p>
                                <PageViewsPerPathChart
                                    gapi={gapi}
                                    viewId={viewId}
                                    query={{
                                        "start-date": fromDate,
                                        "end-date": toDate,
                                        metrics: 'ga:pageviews,ga:uniquePageviews',
                                    }}
                                    options={{
                                        page:false,
                                        pageSize:6
                                    }}
                                />
                            </div>

                            <div className={"col-6 tile dashboardTile"}>
                                <p className={"chartTitle"}>Répartition provenance des visiteurs</p>
                                <SessionsBySourceChart
                                    gapi={gapi}
                                    viewId={viewId}
                                    query={{
                                        "start-date": fromDate,
                                        "end-date": toDate,
                                    }}
                                    options={{
                                        title:'',
                                        animation: {
                                            duration: 1000,
                                            easing: 'out',
                                        },
                                        slices: {
                                            0: {color: '#DC052C'},
                                            1: {color: '#2505dc'},
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-5 tile dashboardTile"}>
                                <p className={"chartTitle"}>Répartition des consultations par appareil</p>
                                <SessionsByDeviceCategoryChart
                                    gapi={gapi}
                                    viewId={viewId}
                                    query={{
                                        "start-date": fromDate,
                                        "end-date": toDate,
                                    }}
                                    options={{
                                        title:'',
                                        animation: {
                                            duration: 1000,
                                            easing: 'out',
                                        },
                                        slices: {
                                            0: {color: '#DC052C'},
                                            1: {color: '#2505dc'},
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    )
                }}
            />
        </div>
        )
}