import React from 'react';

import './Newsletters.css'
import {NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import NewslettersList from "./NewslettersList";
import NewslettersCreation from "./NewslettersCreation";

function Newsletters() {
    document.title = "Newsletters";
    let url = useRouteMatch().url;

    return (
        <div className={"newsletters"}>
            <div className={"flex"}>
                <NavLink to={`${url}/liste`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Historique newsletters
                </NavLink>
                <NavLink to={`${url}/creation`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Création de newsletter
                </NavLink>
            </div>
            <Switch>
                <Route path={`${url}/liste`}>
                    <NewslettersList/>
                </Route>
                <Route path={`${url}/creation`} render={(props) => <NewslettersCreation {...props} />}/>
                <Route>
                    <Redirect to={`${url}/liste`}/>
                </Route>
            </Switch>
        </div>
    );
}

export default Newsletters;
