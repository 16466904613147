import React, {useEffect, useRef, useState} from "react";
import API from "../../utils/ApiParams";
import Table from "../Table";
import Pagination from "../Pagination";
import {getCategories} from "../../utils/Api";
import {FilterAnd} from "../../utils/FilterList";
import "./SubCategoriesList.css";
import Confirm from "../modals/Confirm";
import {Redirect} from "react-router-dom";
import Loading from "../Loading";

export default function SubCategoriesList() {
    const [categories,setCategories] = useState([]);
    const [searchedCategory,setSearchedCategory] = useState({});
    const [subCategories,setSubCategories] = useState([]);
    const [filteredSubCategories,setFilteredSubCategories] = useState([]);
    const [shownItems,setShownItems] = useState(10);
    const [searched,setSearched] = useState("");
    const [page,setPage] = useState(1);

    const [openConfirmDelete,setOpenConfirmDelete] = useState(false);
    const [openConfirmValidate,setOpenConfirmValidate] = useState(false);

    const [subCategoryId, setSubCategoryId] = useState(0);
    const [toSubCatEdition,setToSubCatEdition] = useState({navigate: false,id: null});
    const [toArticleEdition,setToArticleEdition] = useState({navigate: false,id: null, action:""});
    const [isLoading, setIsLoading] = useState(true);
    const [subCategoriesLoaded,setSubCategoriesLoaded] = useState(false);
    const stateRef = useRef();
    stateRef.current = subCategories;
    //Action functions
    function modifySubCategory(id){
        setToSubCatEdition({navigate:true, id: id});
    }
    function deleteSubCategory(id){
        setOpenConfirmDelete(true);
        setSubCategoryId(id);
    }
    async function confirmDelete(confirm) {
        setOpenConfirmDelete(false);
        if (confirm){
            const response = await API.delete("sub_categories/"+subCategoryId);
            if(response.status === 204){
                setSubCategories(subCategories.filter(sc =>parseInt(sc.id) !== subCategoryId))
            }
        }
    }
    function validateSubCategory(id){
        setOpenConfirmValidate(true);
        setSubCategoryId(id);
    }
    async function confirmValidate(confirm) {
        setOpenConfirmValidate(false);
        if (confirm){
            async function getStatuses(){
                const response = await API.get("statuses");
                return response.data;
            }
            async function validateSubCategory(statuses) {
                const response = await API.patch("sub_categories/"+subCategoryId,{
                    status:"/statuses/"+statuses.find(status => status.index === 2).id
                },{
                    headers:{"content-type" : "application/merge-patch+json"}
                })
                return response;
            }
            getStatuses().then(data=> {
                validateSubCategory(data).then(response=>{
                    if (response.status === 200){
                        let tempData = [...stateRef.current];
                        tempData.map(td=>{
                            if(td.id === subCategoryId){
                                td.action =
                                    {format: "button", id : response.data.id, value : [
                                            {handleClick: modifySubCategory, text: "Modifier", className : "actionButton modifyButton"},
                                            (response.data.articles.length === 0) && {handleClick: deleteSubCategory, text: "Supprimer", className : "actionButton deleteButton"},
                                            (response.data.status.index === 1) && {handleClick: validateSubCategory, text: "Valider", className : "actionButton duplicateButton"},
                                        ]};
                                td.rowClassName = "";
                            }
                            return td;
                        });
                        setSubCategories(tempData);
                    }
                })
            });
        }
    }
    function showArticle(id){
        const articleId = stateRef.current.find(sc=> sc.id === id).articleId;
        setToArticleEdition({navigate:true, id: articleId, action:"edit"});
    }
    useEffect(()=>{
        async function getSubCategories() {
            const response = await API.get("sub_categories?order%5Bid%5D=desc");
            return response.data;
        }
        getCategories().then(data=>setCategories(data));
        getSubCategories().then(data=>{
            setSubCategories(data.map(sc=>{
                let sub = {
                    shownId: {format:"txt", value : sc.id},
                    category : {format:"txt", value : sc.category.name},
                    subCategory : {format:"txt", value : sc.name},
                    action : {format: "button", id : sc.id, value : [
                            {handleClick: modifySubCategory, text: "Modifier", className : "actionButton modifyButton"},
                        ]},
                    id: sc.id,
                    defaultAction: {handleClick:modifySubCategory, id:sc.id},
                    rowClassName: sc.status.index === 1 ? "requireAction" : "",
                    articleId : sc.status.index === 1 ? parseInt(sc.articles[0].split('/')[2]) : ""
                }
                if(sc.articles.length === 0){
                    sub.action.value.push({handleClick: deleteSubCategory, text: "Supprimer", className : "actionButton deleteButton"});
                }
                if( sc.status.index === 1){
                    sub.action.value.push({handleClick: validateSubCategory, text: "Valider", className : "actionButton duplicateButton"});
                    sub.action.value.push({handleClick: showArticle, text: "Voir l'article", className : "actionButton previewButton"});
                }
                return sub;
            }))
            setSubCategoriesLoaded(true)
        })
    },[])
    useEffect(()=>{
        if (categories && categories.length>0 && subCategoriesLoaded){
            setIsLoading(false);
        }
    }, [subCategoriesLoaded, categories])
    useEffect(()=>{
        setFilteredSubCategories(FilterAnd(subCategories.filter(sc=>{
            if(Object.keys(searchedCategory).length>0)
                return sc.category.value === searchedCategory.name;
            else return true
            }),
            {subCategory: searched}));


    },[searched, searchedCategory, subCategories])
    useEffect(()=>{
        if(filteredSubCategories.length + shownItems <= shownItems*page){
            if(page>1){
                handleChangePage(page-1);

            } else{
                handleChangePage(1);
            }
        }
    },[filteredSubCategories])

    function handleChangePage(newPage){
        setPage(newPage);
    }
    function handleChangeSearchCategory(e) {
        if(e.target.value){
            setSearchedCategory(categories.find(c => c.id === parseInt(e.target.value)));
        } else setSearchedCategory({})
    }
    if (toSubCatEdition.navigate){
        return (
            <Redirect push to={{
                pathname:"/sous-categories/creation",
                state: {id : toSubCatEdition.id}
            }}/>
        )
    }
    if (toArticleEdition.navigate){
        return (
            <Redirect push to={{
                pathname:"/articles/creation",
                state: {id : toArticleEdition.id, action : toArticleEdition.action}
            }}/>
        )
    }
    return(
        isLoading ? <Loading/>
        : <div className={"subCategoriesList"}>
            {openConfirmDelete &&
            <Confirm handleResponse={confirmDelete}/>
            }
            {openConfirmValidate &&
            <Confirm text={"Êtes-vous sûr de vouloir valider cet élément ?"} handleResponse={confirmValidate}/>
            }
            <div className={"filterBar"}>
                <div className={"filterNumber"}>
                    <input className={"inputNumber"} type="number" value={shownItems}
                           onChange={(e)=>setShownItems(parseInt(e.target.value))}/>
                    <span> enregistrements par page</span>
                </div>
                <select className={`customInput customSelect ${Object.keys(searchedCategory).length > 0 ? "activeFilter" : ""}`}
                        value={searchedCategory.id} onChange={handleChangeSearchCategory}>
                    <option value="" disabled>Catégorie</option>
                    <option value="">Toutes</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
                <input className={`customInput ${searched !== "" ? "activeFilter" : ""}`} placeholder={"Rechercher"}
                       type="text" value={searched} onChange={(e)=>setSearched(e.target.value)}/>
            </div>
            <Table elements={filteredSubCategories}
                    headers={{
                        shownId: "Id",
                        category: "Catégorie",
                        subCategory: "Sous cat.",
                        action: "Action",
                    }}
                   currentPage={page}
                   elementsPerPage={shownItems}
            />
            <div className={"flex space-between pagination"}>
               <span>
                   {shownItems < filteredSubCategories.length ? shownItems : filteredSubCategories.length} enregistrements sur un total de {filteredSubCategories.length} enregistrements
               </span>
                <Pagination currentPage={page} pageElement={shownItems} totalElements={filteredSubCategories.length}
                            pageNeighbours={2} handleChangePage={handleChangePage}/>
            </div>
        </div>
    )
}