import './App.scss';
import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import API from './utils/ApiParams'
import Header from './components/Header'
import Dashboard from './components/dashboard/Dashboard'
import Articles from './components/article/Articles'
import Library from './components/library/Library'
import Newsletters from './components/newsletters/Newsletters'
import Login from './components/login/Login'
import PrivateRoute from "./utils/PrivateRoute";
import {UserProvider} from "./contexts/UserContext";
import jwt_decode from "jwt-decode";
import GetToken from "./utils/GetToken";
import SubCategories from "./components/subCategories/SubCategories";
import ArticleList from "./components/article/ArticleList";

function App() {
    const [user,setUser] = useState({});
    const [userLoaded, setUserLoaded] = useState(false);
    const [notif,setNotif] = useState(0);

    useEffect(()=>{

        getUserFromToken().then(data=>{
            setUser(data);
            setUserLoaded(true);
        })
    }, [])

    async function getUserFromToken() {
        let tempUser = {};
        async function verifyToken(token) {
            const response = await API.post("verifyToken",{token:token});
            return response.data;
        }

        const token = GetToken();
        if(token !== null) {
            await verifyToken(token).then(data=>{
                if(data && data.connection) {
                    const decodedToken = jwt_decode(token);
                    tempUser = {role: decodedToken.access, id: decodedToken.id};
                }
            }).catch(e=>{
                // console.log(e.response);
            });
        }
        return tempUser;
    }

    function disconnect() {
        setUser({});
    }
    function log(role, id) {
        setUser({role,id});
    }
    function isLogged() {
        let tokenExpiryDate = 0;
        const token = GetToken();
        if(token !== null)
            tokenExpiryDate = jwt_decode(token).exp;
        return Object.keys(user).length>0 && tokenExpiryDate*1000 > Date.now();
    }
    async function getArticlesToValidate() {
        const response = await API.get("articles?isArchived=false&status.index=1");
        return response.data;
    }
    useEffect(()=>{

        if(user.role === 1)
            getArticlesToValidate().then(data => setNotif(data.length))
    }, [user])

    function reloadArticlesToValidate() {
        if(user.role === 1)
            getArticlesToValidate().then(data => setNotif(data.length))
    }

  return (
      <div className={"flexContainer"}>
          <UserProvider value={user}>
              <Router>
                  {isLogged() &&
                       <Header isLogged={isLogged()} notifs={notif} onChange={disconnect}/>
                  }
                  <div className={"container"}>
                      {userLoaded &&
                      <Switch>
                          <PrivateRoute path={"/dashboard"} condition={isLogged()}>
                              <Dashboard notifs={notif}/>
                          </PrivateRoute>
                          <PrivateRoute path={"/articles"} condition={isLogged()}>
                              <Articles reload={reloadArticlesToValidate}/>
                          </PrivateRoute>
                          <PrivateRoute path={"/sous-categories"} condition={isLogged()}>
                              <SubCategories/>
                          </PrivateRoute>
                          <PrivateRoute path={"/mediatheque"} condition={isLogged()}>
                              <Library/>
                          </PrivateRoute>
                          <PrivateRoute path={"/newsletters"} condition={user.role === 1}>
                              <Newsletters/>
                          </PrivateRoute>
                          <Route path={"/authentification"}>
                              <Login onLog={log}/>
                          </Route>

                          <Route>
                              {isLogged()
                                  ? <Redirect to={"/dashboard"}/>
                                  : <Redirect to={"/authentification"}/>
                              }
                          </Route>
                      </Switch>
                      }
                  </div>
              </Router>
          </UserProvider>
      </div>
  );
}

export default App;
