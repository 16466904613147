export default function GetToken() {
    if(document.cookie){
        const cookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('token='));

        if(cookie){
            return cookie.split('=')[1];
        }
    }
    return null;
}