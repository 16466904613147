import API from './ApiParams'

export async function getCategories(){
    const response = await API.get("categories");
    return response.data;
}

export async function getSubCategories(){
    const response = await API.get("sub_categories?status.index=2");
    return response.data;
}

export async function uploadFile(file, endpoint="media_objects", onlyId = true) {
    let formData = new FormData();
    formData.append("file",file);
    const response = await API.post(endpoint,
        formData,
        {
            headers:{"Content-type" : "multipart/form-data"}
        });
    if(onlyId)
        return response.data.id;
    else
        return response.data;
}

export async function getDefaultImage() {
    const response = await API.get("media_objects?isLibrary=true&isArchived=false&page=1&itemsPerPage=1");
    return response.data[0];
}
