import React from 'react';
import {NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import SubCategoriesList from "./SubCategoriesList";
import SubCategoriesCreation from "./SubCategoriesCreation";
import "./SubCategories.css";

export default function SubCategories() {
    let url = useRouteMatch().url;

    return(
        <div className={"subCategories"}>
            <div className={"flex"}>
                <NavLink to={`${url}/liste`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Listing des sous-catégories
                </NavLink>
                <NavLink to={`${url}/creation`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Création d'une sous-catégorie
                </NavLink>
            </div>
            <Switch>
                <Route path={`${url}/liste`}>
                    <SubCategoriesList/>
                </Route>
                <Route path={`${url}/creation`} render={(props) => <SubCategoriesCreation {...props}/>}/>
                <Route>
                    <Redirect to={`${url}/liste`}/>
                </Route>
            </Switch>
        </div>
    )
}