import React, {useEffect, useState, useRef, useContext} from 'react';
import './ArticleCreation.css'
import CustomCheckbox from "../CustomCheckbox";
import {getCategories, getDefaultImage, getSubCategories, uploadFile} from "../../utils/Api";
import {Redirect} from "react-router-dom";
import API from '../../utils/ApiParams'
import LibraryList from "../library/LibraryList";
import UserContext from "../../contexts/UserContext";
import Confirm from "../modals/Confirm";
import BiggerImage from "../modals/BiggerImage";
import Preview from "../modals/Preview";
import Editor from "../customEditor/Editor";
import ArticlePreview from "./ArticlePreview";
import Loading from "../Loading";
import Download from "../../images/download.svg";

function ArticleCreation(props) {
    const shortEditor = useRef(null);
    const longEditor = useRef(null);
    const libraryRef = useRef(null);
    const showLibraryRef = useRef(null);
    const libraryThumbnailRef = useRef(null);
    const showLibraryThumbnailRef = useRef(null);


    const user = useContext(UserContext);

    const [tempShortText,setTempShortText] = useState(null);
    const [tempText,setTempText] = useState(null);
    const [categories,setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [areSubCategoriesLoaded, setAreSubCategoriesLoaded] = useState(false);
    const [statuses, setStatuses] = useState([])

    const [isLoading,setIsLoading] = useState(false);
    const [errorFileMsg,setErrorFileMsg] = useState(false);
    const [isUploading,setIsUploading] = useState(false);
    const [toList,setToList] = useState(false);
    const [showLibrary,setShowLibrary] = useState(false);
    const [showLibraryThumbnail,setShowLibraryThumbnail] = useState(false);
    const [status,setStatus] = useState(null);

    const [id,setId] = useState(null);
    const [modify, setModify] = useState(false);
    const [title, setTitle] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubCategories,setSelectedSubCategories] = useState([]);
    const [newSubCatCategory,setNewSubCatCategory] = useState({name:"",id:0});
    const [newSubCategoryText, setNewSubCategoryText] = useState("");
    const [questions, setQuestions] = useState([{question:""}]);
    const [isFrontPage, setIsFrontPage] = useState(false);
    const [stringifiedShortText,setStringifiedShortText] = useState(null);
    const [stringifiedText,setStringifiedText] = useState(null);
    const [coverImage,setCoverImage] = useState([]);
    const [image,setImage] = useState(null);
    const [newThumbnail,setNewThumbnail] = useState([]);
    const [thumbnail,setThumbnail] = useState(null);
    const [newFiles, setNewFiles] = useState([]);
    const [files,setFiles] = useState([]);
    const [watchDownloadButtonNewFile,setWatchDownloadButtonNewFile] = useState(false);

    const [submitButton,setSubmitButton] = useState("");
    const [errors,setErrors] = useState({});
    const [showCreated,setShowCreated] = useState(false);
    const [hasBeenSaved,setHasBeenSaved] = useState(false);
    const [delArtId,setDelArtID] = useState(null);
    const [openConfirm,setOpenConfirm] = useState(false);
    const [inputFile,setInputFile] = useState("");

    const [bigImage, setBigImage] = useState({});

    const [hasBeenPreviewed,setHasBeenPreviewed] = useState(false);
    const [errorNotPreviewed, setErrorNotPreview] = useState(false);
    const [showArticlePreview,setShowArticlePreview] = useState(false);
    const [author, setAuthor] = useState({});

    const textRef = useRef(null);
    textRef.current = stringifiedText;
    function useOutsideAlerter(ref, secondRef, cb) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !secondRef.current.contains(event.target)) {
                    cb();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(libraryRef,showLibraryRef, ()=>setShowLibrary(false));
    useOutsideAlerter(libraryThumbnailRef,showLibraryThumbnailRef, ()=>setShowLibraryThumbnail(false));

    async function getArticle(){
        const response = await API.get("articles/"+ props.location.state.id);
        return response.data;
    }

    useEffect(()=>{

        async function getStatuses(){
            const response = await API.get("statuses");
            return response.data;
        }
        async function getAuthor(){
            const response = await API.get(`users/${user.id}`);
            return response.data;
        }

        setIsLoading(true);
        getCategories().then(data => setCategories(data));
        getSubCategories().then(data => {
            setSubCategories(data);
            setAreSubCategoriesLoaded(true);
        });

        getStatuses().then(data => setStatuses(data));

        if(props.location.state){
            if (props.location.state.id){
                setId(props.location.state.id);
                setHasBeenPreviewed(true);
                getArticle().then(article => {
                    if(props.location.state.action === "duplicate"){
                        article.questions.map(function (question) {
                            question.id = null;
                            question.questionsAnswers = null;
                            return question;
                        })
                        setSelectedSubCategories(article.subCategories.filter(sc=> sc.status.index === 2))
                    }
                    setTempShortText(article.shortText);
                    setTempText(article.text);
                    setImage(article.coverImage);
                    if(article.thumbnail)
                        setThumbnail(article.thumbnail);
                    if(props.location.state.action === "edit"){
                        setFiles(article.files.map(function (file) {
                            file.removed = false;
                            return file;
                        }));
                        setTitle(article.title);
                        setStatus(article.status.index);
                        setModify(true);
                        setHasBeenSaved(true);
                        setSelectedSubCategories(article.subCategories);
                    }
                    setQuestions(article.questions.length>0?article.questions:[{question:"",id: null}])
                    setSelectedCategories(article.categories.sort((a,b)=>{return a.position - b.position}));
                    setIsFrontPage(article.isFrontPage);
                    setAuthor(article.author);
                    setIsLoading(false);
                })
            }
        }else{
            getDefaultImage().then(data=>{
                if(data){
                    setImage(data)
                }
            });
            getAuthor().then(data=> setAuthor(data));

        }
    }, [props.location.state]);

    useEffect(()=>{
        if(categories && subCategories && (!props.location.state || tempShortText)) {
            if (categories.length > 0 && (areSubCategoriesLoaded || subCategories.length>0) && (!props.location.state || tempShortText.length>0))
                setIsLoading(false);
            else
                setIsLoading(true);
        }
        else
            setIsLoading(true);

    }, [categories,subCategories,tempText,tempShortText,props.location.state]);

    useEffect(()=>{
        if(showCreated)
            setTimeout(()=>{
                setShowCreated(false)
            },2000)
    },[showCreated])

    useEffect(()=>{

        setTimeout(()=>{
            if(Object.keys(errors).length>0){
                document.getElementById(Object.keys(errors)[0]).scrollIntoView({behavior: "smooth",block: "center", inline: "center"});
            }
        },50)
    }, [errors])

    // "Complex Changes" on input change
    function handleChangeCategory(event) {
        let tmpCat = selectedCategories;
        if(!tmpCat.some(sc=>sc.id === parseInt(event.target.value))){
            tmpCat = [...tmpCat, categories.find(sc => sc.id === parseInt(event.target.value))];
            tmpCat.sort((a,b)=>{return a.position - b.position});
            setSelectedCategories(tmpCat);
        }
        delete errors.category;
    }

    function removeCategory(selectedCategory) {
        setSelectedCategories(selectedCategories.filter(Category => Category !== selectedCategory));
        setSelectedSubCategories(selectedSubCategories.filter(sc => sc.category.id !== selectedCategory.id));

        if(selectedCategory.id === newSubCatCategory.id)
            setNewSubCatCategory({name:"", id:0})
    }

    function handleChangeSubCategory(event) {
        if(!selectedSubCategories.some(sc=>sc.id === parseInt(event.target.value)))
            setSelectedSubCategories(selectedSubCategories =>
                [...selectedSubCategories, subCategories.find(sc => sc.id === parseInt(event.target.value))]);
    }

    function removeSubCategory(selectedSubCategory) {
        setSelectedSubCategories(selectedSubCategories.filter(subCategory => subCategory !== selectedSubCategory));
        if (selectedSubCategory.status.index === 1 && selectedSubCategory.id !== 0){
            API.delete("sub_categories/"+selectedSubCategory.id);
        }
    }

    function handleChangeCategoryNewSub (event){
        setNewSubCatCategory(categories.find(sc => sc.id === parseInt(event.target.value)));

    }

    function addNewSubCategory() {
        if(newSubCatCategory.id>0){
            if(selectedSubCategories.every(scb => scb.status.index !== 1))
                setSelectedSubCategories([...selectedSubCategories,
                    {id: 0, category: newSubCatCategory, name: newSubCategoryText, status:statuses.find(s=> s.index === 1)}])
        setNewSubCategoryText("");
        }
    }

    async function uploadSubCat(sbc){
        if(sbc && sbc.id === 0){
            const data = {
                name:sbc.name,
                category: "/categories/"+sbc.category.id,
                status: "/statuses/"+sbc.status.id
            };
            const response = await API.post("sub_categories",data)
            return response.data;
        } else return null;
    }

    function validateSubCategory(){
        const newSub = selectedSubCategories.find(ssc=> ssc.status.index === 1);
        async function updateSubCat() {
            const response = await API.patch("sub_categories/"+newSub.id,{
                status:"/statuses/"+statuses.find(status => status.index === 2).id
            },{
                headers:{"content-type" : "application/merge-patch+json"}
            })
            return response.data;
        }

        if(newSub.id === 0){
            newSub.status = statuses.find(status => status.index === 2)
            uploadSubCat(newSub).then(data => {
                setSelectedSubCategories(selectedSubCategories.map(ssc => {
                    if (ssc.id === 0){
                        ssc.id = data.id;
                        ssc.status = data.status
                        newSub.id = data.id;
                    }
                    return ssc;
                }))
            })
        }
        else {
            updateSubCat().then(data =>{
                setSelectedSubCategories(selectedSubCategories.map(ssc => {
                if (ssc.id === newSub.id)
                    ssc.status = data.status;
                return ssc;
                }))
            })
        }
    }

    function handleChangeQuestion(event, index) {
        let tempQuestions = [...questions];
        tempQuestions[index].question = event.target.value;
        setQuestions(tempQuestions);
        if(errors.questions && errors.questions[index]){
            errors.questions[index]=null;
        }
    }

    function removeQuestion(index) {
        setQuestions(questions.filter((question,i) => i !== index))
    }

    function handleChangeShortText(shortText) {
        setStringifiedShortText(shortText);
        delete errors.shortText;
    }

    function handleChangeText(text) {
        setStringifiedText(text);
        delete errors.text;
    }

    function handleChangeImage(event){
        if(event.target.files.length>0){
            setCoverImage(event.target.files);
            setImage(null);
            delete errors.image;
        }
    }

    function handleChangeImageThumbnail(event){
        if(event.target.files.length>0){
            setNewThumbnail(event.target.files);
            setThumbnail(null);
            delete errors.thumbnail;
        }
    }

    function handleChangeLibraryImage(libraryImage){
        setImage({id: libraryImage.id, name: libraryImage.name});
        setCoverImage ([]);
        setShowLibrary(false);
        delete errors.image;
    }

    function handleChangeLibraryImageThumbnail(libraryImage){
        setThumbnail({id: libraryImage.id, name: libraryImage.name});
        setNewThumbnail ([]);
        setShowLibraryThumbnail(false);
        delete errors.thumbnail;
    }

    function handleChangeFile(event){
        if(event.target.files.length>0){
            let tempFile = [...newFiles];
            Array.from(event.target.files).forEach(function (newFile) {
                    if(newFile.size <= 10e6 && newFile.type!=''){
                        if(!tempFile.some(file => file.name === newFile.name))
                            tempFile = [...tempFile, newFile]
                    }else{
                        setErrorFileMsg(true);
                    }
                }
            )
            setNewFiles(tempFile);
        }
    }

    function removeNewFile(fileToRemove) {
        setNewFiles(files => files.filter(file=> file.name !== fileToRemove.name));
        setInputFile("");
    }

    function removeFile(fileToRemove) {
        setOpenConfirm(true);
        setDelArtID(fileToRemove.id);
    }

    function downloadFile(file) {
        window.open(process.env.REACT_APP_API_URL+`download/${file.id}/${file.name}`, '_blank')
    }

    function removeFileID(shouldDelFile) {
        if (shouldDelFile){
            setFiles(files => files.filter(fi => fi.id !== delArtId));
        }
        setOpenConfirm(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        async function uploadEditor(){
            await Promise.all([shortEditor.current.uploadImages(), longEditor.current.uploadImages()]);
        }
        async function uploadArticle(data) {
            const response = await API.post("articles",
                data,
                {
                    headers:{"content-type" : "application/json"}
                })
            return response.data;
        }
        async function updateArticle(data) {
            const response = await API.patch("articles/"+id,
                data,
                {
                    headers:{"content-type" : "application/merge-patch+json"}
                })
            return response.data;
        }

        if (isFormValid()){
            if(!hasBeenSaved){
                setHasBeenSaved(true);
            }
            setIsUploading(true)
            let idImage;
            let idThumbnail;
            let idFiles = [];

            async function uploadFiles() {
                let ids = await Promise.all(newFiles.map(async (file) =>{
                    let id = await uploadFile(file,"file_objects");
                    return id
                }));
                files.forEach(file =>{
                    if(!file.removed)
                        ids = [...ids,file.id];
                    else {
                        API.delete("file_objects/"+file.id);
                    }
                });
                return ids;
            }

            await uploadFiles().then(data=>{
                idFiles = data;
            });
            if (coverImage.length > 0){
                await uploadFile(coverImage[0]).then(data => {
                    idImage = data;
                });
            }
            else idImage = image.id;
            if (newThumbnail.length > 0){
                await uploadFile(newThumbnail[0]).then(data => {
                    idThumbnail = data;
                });
            }
            else idThumbnail = thumbnail.id;
            await uploadEditor().then(()=>{
                const newSub = selectedSubCategories.find(ssc=> ssc.status.index === 1);
                uploadSubCat(newSub).then((data) =>{
                    selectedSubCategories.map(ssc=>{
                        if(ssc.id === 0)
                            ssc.id = data.id
                        return ssc;
                    })
                    const article = {
                        title: title,
                        coverImage: "/media_objects/"+idImage,
                        thumbnail: "/media_objects/"+idThumbnail,
                        categories: selectedCategories.map(category => "/categories/" + category.id),
                        subCategories: selectedSubCategories.map(scb => "/sub_categories/"+scb.id),
                        shortText: stringifiedShortText,
                        text: (textRef.current ? textRef.current : ''),
                        isFrontPage: isFrontPage,
                        files: idFiles.map(id => "/file_objects/"+id),
                    }
                    if (!modify){
                        article.creationDate = new Date(Date.now());
                        article.author = "users/"+user.id;
                    }
                    if (questions[0].question.length > 0){
                        article.questions = questions;
                    }
                    if (submitButton === "draft"){
                        article.status = "/statuses/"+statuses.find(s => s.index === 0).id;
                    }
                    else if (submitButton === "submit"){
                        article.status = "/statuses/"+statuses.find(s => s.index === 1).id;
                    }
                    else if (submitButton === "validate"){
                        article.status = "/statuses/"+statuses.find(s => s.index === 2).id;
                    }

                    if (modify){
                        article.modificationDate = new Date();
                        updateArticle(article).then(data=> {
                            if (data.questions.length>0){
                                setQuestions(data.questions)
                            }
                            if(submitButton === "draft"){
                                setId(data.id);
                                setShowCreated(true)
                                setIsUploading(false);
                            }
                            else{
                                props.reload();
                                setToList(true)
                            }
                        });
                    }
                    else{
                        uploadArticle(article).then(data => {
                            if(submitButton === "draft"){
                                if (data.questions.length>0){
                                    setQuestions(data.questions)
                                }
                                setId(data.id);
                                setShowCreated(true)
                                setModify(true)
                                setIsUploading(false);
                            }
                            else{
                                props.reload();
                                setToList(true)
                            }
                        });
                    }
                })
            });
        }
        else{
            setIsUploading(false);
        }
    }

    function isFormValid(fromPreview=false) {
        let isValid = true;
        let tempErrors = {};
        if (coverImage.length === 0 && image === null){
            isValid=false;
            tempErrors.image = "Il n'y a pas de visuel";
        }
        if (newThumbnail.length === 0 && thumbnail === null){
            isValid=false;
            tempErrors.thumbnail = "Il n'y a pas de visuel";
        }
        if (title.length === 0){
            isValid=false;
            tempErrors.title = "Le champ titre est vide.";
        }
        if(selectedCategories.length === 0){
            isValid=false;
            tempErrors.category = "Le champ catégorie est vide";
        }
        if (questions.length>1){
            questions.forEach(function (question,i) {
                if (question.question.length === 0){
                    isValid=false;
                    if(!tempErrors.questions)
                        tempErrors.questions = [];
                    tempErrors.questions.push(`Le champ question n°${i+1} est vide`);
                }
            })
        }
        if (!stringifiedShortText || stringifiedShortText.length === 0){
            isValid=false;
            tempErrors.shortText = "Le champ texte court est vide";
        }
        // if (!stringifiedText || stringifiedText.length === 0){
        //     isValid=false;
        //     tempErrors.text = "Le champ texte principal est vide";
        // }
        if(!hasBeenPreviewed && !fromPreview && user.role !== 1){
            isValid=false;
            tempErrors.preview = "L'article n'a pas été prévisualisé";
            setErrorNotPreview(true);
        }
        setErrors(tempErrors);
        return isValid;
    }
    function preview() {
        if(isFormValid(true)){
        setHasBeenPreviewed(true);
        setShowArticlePreview(true);
        }
    }

    // Rendering functions
    function showError(field, errorStyle=true) {
        if (errors[field]){
            return (
                <div className={`${errorStyle?"error":""}`} id={field}>
                    {Array.isArray(errors[field])
                    ? errors[field].map((question,i) =>{
                        return(
                            <div key={i}>
                                {question}
                            </div>
                        )})
                    : errors[field]}
                </div>
            )
        }
        else return null;
    }

    function header() {
        let title;
        if (props.location.state){
            if (props.location.state.action === "edit"){
                title = "Édition";
            }
            else {
                title = "Création";
            }
        } else{
            title = "Création";
        }
        return title;
    }

    function showBigImage(cover = true) {
        if(cover){
            if(image){
                setBigImage({url: process.env.REACT_APP_API_URL+"images/"+image.id+"/"+image.name});
            }else{
                setBigImage({url:URL.createObjectURL(coverImage[0])});
            }
        } else {
            if(thumbnail){
                setBigImage({url: process.env.REACT_APP_API_URL+"images/"+thumbnail.id+"/"+thumbnail.name});
            }else{
                setBigImage({url:URL.createObjectURL(newThumbnail[0])});
            }
        }
    }

    function errorFile() {
        return(
            <div>
                Le fichier est incorrect : <br />
                &nbsp;&nbsp;- Le poids doit être inférieur à 10mo<br />
                &nbsp;&nbsp;- Une extension de type .jpg, .doc, ... doit être renseignée<br />
            </div>
        )
    }

    function save() {
        return(
            <div>
                Votre article a bien été enregistré
            </div>
        )
    }
    if(toList){
        return (<Redirect to={"/articles/liste"}/>)
    }
    return (
        <form className={"articleCreation"} onSubmit={handleSubmit}>
            <BiggerImage image={bigImage} unsetImage={()=>setBigImage({})}/>
            {openConfirm &&
                <Confirm handleResponse={removeFileID}/>
            }
            <h2 className={"pageTitle col-12"}>{header()} d'un article</h2>
            <div className={"col-8 leftCol"}>
                <div className={"col-12 tile text"}>
                    <p className={"blocTitle"}>
                        Informations générales
                    </p>
                    <label>
                        Titre
                        <input placeholder={"Ex : La vente de boissons"} required className={"articleInput"} type="text" value={title}
                               onChange={(e)=>{
                                   setTitle(e.target.value)
                                    delete errors.title;
                               }}/>
                        {showError("title")}
                    </label>
                    <label>
                        <p>Catégories</p>
                        {categories.length === 0
                        ? <Loading height={"75px"} width={"75px"} justifyContent={"flex-start"}/>
                        :
                            <select className={"articleInput customSelect"} value={""} onChange={handleChangeCategory}>
                                <option value="" disabled>Ajouter une catégorie</option>
                                {categories.map((category) => !selectedCategories.some(sc => sc.id === category.id)
                                    ?
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                    : ""
                                )}
                            </select>
                        }
                        {selectedCategories.map((selectedCategory) => (
                            <button type={"button"} onClick={() => removeCategory(selectedCategory)}
                                    className={`clickableCategoryButton category-${selectedCategory.id}-full category-${selectedCategory.id}-hover`}
                                    key={selectedCategory.id}>{selectedCategory.name} <span
                                className={"delete"}>✕</span></button>
                        ))}
                        {showError("category")}
                    </label>
                    <label>
                        <p>Sous-catégories</p>
                        {subCategories.length === 0
                            ? <Loading height={"75px"} width={"75px"} justifyContent={"flex-start"}/>
                            :
                            <select className={"articleInput customSelect"} value={""}
                                    onChange={handleChangeSubCategory}>
                                <option value="" disabled>Ajouter une sous-catégorie</option>
                                {subCategories.map((subCategory) =>
                                    (!selectedSubCategories.some(sbc => sbc.id === subCategory.id)
                                        && selectedCategories.some(sc => sc.id === subCategory.category.id))
                                        ?
                                        <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                                        : ""
                                )}
                            </select>
                        }
                        {selectedSubCategories.map((selectedSubCategory) => (
                            selectedSubCategory.status.index === 2 &&
                            <button type={"button"} onClick={() => removeSubCategory(selectedSubCategory)} className={`categoryButton subCategoryButton ${selectedSubCategory.status.index===1?"newSubCategory":""}`}
                                    key={selectedSubCategory.id?selectedSubCategory.id:0}>{selectedSubCategory.name} <span
                                className={"delete"}>✕</span></button>
                        ))}

                    </label>
                    <label>
                        Associer une question fermée à l'article
                        {questions.map((question, index) => (
                            <div className={"question"} key={index}>
                                <span className={"questionNb"}>{index + 1}</span>
                                <input placeholder={"Ex : Avez-vous apprécié l’article ?"} disabled={question.questionsAnswers && question.questionsAnswers.length>0} className={"articleInput"} type="text" value={question.question}
                                       onChange={e => handleChangeQuestion(e, index)}/>
                                {questions.length > 1 &&
                                <button disabled={question.questionsAnswers && question.questionsAnswers.length>0} type="button" className={"questionDelete"}
                                        onClick={() => removeQuestion(index)}>✕</button>
                                }
                            </div>
                        ))}
                        <button type="button" className={"articleButtons"}
                                onClick={() => setQuestions(questions => [...questions, {question:"",id: null}])}>Ajouter une question
                        </button>
                        {showError("questions")}
                    </label>
                    <label className={"checkboxLabel"}>
                        <CustomCheckbox checked={isFrontPage} onChange={(e)=>setIsFrontPage(e.target.checked)}/>
                        Article à la une ?
                    </label>
                </div>

                <div className={`col-12 tile text ${(selectedSubCategories.some(ssc => ssc.status.index === 1) && user.role === 1) && "actionRequired"}`}>
                    <p className={"blocTitle"}>
                        Suggestion de sous-catégorie
                    </p>
                    {selectedSubCategories.map((selectedSubCategory) => (
                        selectedSubCategory.status.index === 1 &&
                        <button type={"button"} onClick={() => removeSubCategory(selectedSubCategory)} className={`categoryButton newSubCategory`}
                                key={selectedSubCategory.id?selectedSubCategory.id:0}>{selectedSubCategory.name} <span
                            className={"delete"}>✕</span></button>
                    ))}
                    {(user.role === 1 && selectedSubCategories.some(ssc => ssc.status.index === 1)) &&
                    <div>
                        <button type={"button"} className={"articleButtons articleValidate"} onClick={()=>validateSubCategory()}>
                            Valider
                        </button>
                    </div>
                    }
                    <label>
                        Suggérer une sous-catégorie
                        <p style={{"fontWeight":"normal","marginTop":"0.5rem"}}>Catégories</p>
                        {categories.length === 0
                            ? <Loading height={"75px"} width={"75px"} justifyContent={"flex-start"}/>
                            :
                            <select className={"articleInput customSelect"} value={newSubCatCategory.id} onChange={handleChangeCategoryNewSub}>
                                <option value={0} disabled>Sélectionner la catégorie</option>
                                {selectedCategories.map((category) =>
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                )}
                            </select>
                        }
                        <input disabled={newSubCatCategory.id === 0} placeholder={"Ex : Nouvelles Technologies"} className={"articleInput"} type="text" value={newSubCategoryText}
                               onChange={(e)=>setNewSubCategoryText(e.target.value)}
                        />
                        <button disabled={selectedSubCategories.some(ssc => ssc.status.index === 1)} type={"button"} className={"articleButtons"} onClick={()=>addNewSubCategory()}>
                            Suggérer
                        </button>
                    </label>
                </div>

                <div className={"col-12 tile text"}>
                    <p className={"blocTitle"}>
                        Texte court
                    </p>
                    <div>
                        {isLoading
                            ? <Loading/>
                            :
                            <Editor ref={shortEditor} placeholder={"200 caractères maximum"} handleChange={handleChangeShortText}
                            oldContent={tempShortText} maxChars={200} options={2} height={200}/>
                        }
                    </div>
                    {showError("shortText")}
                </div>


                <div className={"col-12 tile text"}>
                    <p className={"blocTitle"}>
                        Texte principal
                    </p>
                    <div>
                        {isLoading
                            ? <Loading/>
                            :
                            <Editor ref={longEditor} placeholder={"Tapez votre texte"} handleChange={handleChangeText}
                                    oldContent={tempText} height={1000}/>
                        }

                    </div>
                    {showError("text")}
                </div>
            </div>



            <div className={"col-4 rightCol"}>
                <div className={"tile file"}>
                <p className={"blocTitle"}>
                    Vignette <span style={{textTransform:"none"}}>(500 x 500)</span>
                </p>
                {(newThumbnail.length > 0 || thumbnail !== null) &&
                <img onClick={()=> showBigImage(false)} className={"image"} src={newThumbnail.length > 0
                    ? URL.createObjectURL(newThumbnail[0])
                    : process.env.REACT_APP_API_URL+"images/"+thumbnail.id+"/"+thumbnail.name} alt=""/>
                }
                {showError("thumbnail")}
                <div className={"col-12 flex space-between visual"}>
                    <label ref={showLibraryThumbnailRef} className={"buttonImageInput"} onClick={()=> setShowLibraryThumbnail(!showLibraryThumbnail)}>
                        Sélectionner un visuel depuis la médiathèque<span className={"add"}> +</span>
                    </label>
                    <label className={"buttonImageInput"}>
                        Sélectionner un visuel depuis mon ordinateur<span className={"add"}> +</span>
                        <input id={"coverImage"} className={"fileInput"} type="file" accept="image/*" onChange={handleChangeImageThumbnail}/>
                    </label>
                </div>
                <div className={`libraryDisplayWrapper ${showLibraryThumbnail && "showLibrary"}`}/>
                <div ref={libraryThumbnailRef} className={`libraryDisplay ${showLibraryThumbnail && "showLibrary"}`}>
                    <LibraryList classNames={"resizedImageWrapper"} addImage={true} imageClick={handleChangeLibraryImageThumbnail} cols={Math.floor((window.innerWidth/324)*90/100) >=4 ? 4 : 3} rows={2}/>
                </div>


            </div>
                <div className={"tile file"}>
                    <p className={"blocTitle"}>
                        Image de couverture <span style={{textTransform:"none"}}>(Format paysage)</span>
                    </p>
                    {(coverImage.length > 0 || image !==null) &&
                    <img onClick={()=> showBigImage()} className={"image"} src={coverImage.length > 0
                        ? URL.createObjectURL(coverImage[0])
                        : process.env.REACT_APP_API_URL+"images/"+image.id+"/"+image.name} alt=""/>
                    }
                    {showError("image")}
                    <div className={"col-12 flex space-between visual"}>
                        <label ref={showLibraryRef} className={"buttonImageInput"} onClick={()=> setShowLibrary(!showLibrary)}>
                            Sélectionner un visuel depuis la médiathèque<span className={"add"}> +</span>
                        </label>
                        <label className={"buttonImageInput"}>
                            Sélectionner un visuel depuis mon ordinateur<span className={"add"}> +</span>
                            <input id={"coverImage"} className={"fileInput"} type="file" accept="image/*" onChange={handleChangeImage}/>
                        </label>
                    </div>
                    <div/>
                    <div className={`libraryDisplayWrapper ${showLibrary && "showLibrary"}`}/>
                    <div ref={libraryRef} className={`libraryDisplay ${showLibrary && "showLibrary"}`}>
                        <LibraryList classNames={"resizedImageWrapper"} addImage={true} imageClick={handleChangeLibraryImage} cols={Math.floor((window.innerWidth/324)*90/100) >=4 ? 4 : 3} rows={2}/>
                    </div>
                </div>
                <div className={"tile file"}>
                    <p className={"blocTitle"}>
                        Fichiers <span style={{textTransform:"none"}}>(10Mo max/fichier)</span>
                    </p>
                    {files.length > 0 &&
                        files.map(file =>(
                            <>
                                {/*<button key={file.id} type="button" className={`fileButton`}*/}
                                {/*        onClick={() => removeFile(file)}>*/}
                                {/*    {file.name}<span className={"delete"}>{file.removed?"+":"✕"}</span>*/}
                                {/*</button>*/}
                                <div className={`fileButtonDiv`}>
                                    <span>{file.name}</span>
                                    <button key={file.id} type="button" className={`buttons buttonDownload`}
                                            onClick={() => downloadFile(file)}><img src={Download} alt=""/>
                                    </button>
                                    <button key={file.id} type="button" className={`buttons buttonDelete`}
                                            onClick={() => removeFile(file)}>
                                        <span className={"delete"}>✕</span>
                                    </button>
                                </div>
                            </>
                        ))
                    }
                    {newFiles.length > 0 &&
                    newFiles.map((file, i) => (
                        // <button key={i} type="button" className={"fileButton"} onClick={() => removeNewFile(file)}>
                        //     {file.name}<span className={"delete"}>✕</span>
                        // </button>
                        <>
                            <div className={`fileButtonDiv`}>
                                <span>{file.name}</span>
                                {/*{watchDownloadButtonNewFile &&*/}
                                {/*    <button key={file.id} type="button" className={`buttons buttonDownload`}*/}
                                {/*            onClick={() => downloadFile(file)}><img src={Download} alt=""/>*/}
                                {/*    </button>*/}
                                {/*}*/}
                                <button key={file.id} type="button" className={`buttons buttonDelete`}
                                        onClick={() => removeNewFile(file)}>
                                    <span className={"delete"}>✕</span>
                                </button>
                            </div>
                        </>

                    ))
                    }
                    <label className={"buttonFileInput"}>
                        Ajouter un fichier<span className={"add"}> +</span>
                        <input multiple className={"fileInput"} type="file" accept="*" value={inputFile} onChange={handleChangeFile}/>
                    </label>


                </div>
                { questions.some(q => q.questionsAnswers && q.questionsAnswers.length > 0) &&
                    <div className={"tile file"}>
                        <p className={"blocTitle"}>
                            Résultat des questions
                        </p>
                        <div className={"questionAnswers"}>
                            {questions.map(question => {
                                let countAnswers = {};
                                if (question.questionsAnswers && question.questionsAnswers.length > 0)
                                    question.questionsAnswers.forEach(qa => {
                                        countAnswers[qa.answer] = countAnswers[qa.answer] ? countAnswers[qa.answer] + 1 : 1;
                                    })
                                return (
                                    question.questionsAnswers && question.questionsAnswers.length > 0 && (
                                        <div className={"questionAnswer"} key={question.id}>
                                            <div className={"question"}>
                                                {question.question}
                                            </div>
                                            <div className={"answers"}>
                                                {Object.entries(countAnswers).map((ca, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {ca[0]} : {ca[1]}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>)
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            {errorNotPreviewed &&
                <Preview isOpen={errorNotPreviewed} handleClose={()=>setErrorNotPreview(false)} boxWrapperClass={"warningModal"}
                component={showError("preview",false)} showClose={false}/>
            }
            { (!isLoading && showArticlePreview) &&
                <Preview isOpen={showArticlePreview} handleClose={()=>setShowArticlePreview(false)}
                component={<ArticlePreview article={{
                    categories : selectedCategories,
                    subCategories: selectedSubCategories,
                    title: title,
                    author: author,
                    creationDate: Date.now(),
                    text: stringifiedText,
                    shortText: stringifiedShortText
                }}/>}
                boxWrapperClass={"previewOverflow"}/>
            }
            { errorFileMsg &&
            <Preview isOpen={errorFileMsg} handleClose={()=>setErrorFileMsg(false)} showClose={false} boxWrapperClass={"warningModal"}
                     component={errorFile()}/>
            }
            {(!isLoading && showCreated) &&
                <Preview isOpen={showCreated} handleClose={()=>setShowCreated(false)} showClose={false} boxWrapperClass={"savedArticle"}
                         component={save()}/>
            }
            {!isLoading &&
            <div className={"col-12"}>
                <button onClick={()=>preview()} type="button" className={"articleCreationButton previewArticleButton"}>
                    Prévisualiser l'article
                </button>
                {(status === null || status < 1) &&
                    <input disabled={isUploading || !hasBeenPreviewed} className={"articleCreationButton submitArticleButton"} type="submit"
                           value="Enregistrer l'article"
                           onClick={() => {
                               setSubmitButton("draft");
                           }}
                    />
                }
                {(user.role !== 1 && (status === null || status <= 1)) &&
                    <input disabled={isUploading || !hasBeenSaved || !hasBeenPreviewed} className={"articleCreationButton submitArticleButton submitToValidationButton"} type="submit"
                           value="Soumettre l'article"
                           onClick={() => setSubmitButton("submit")}
                    />
                }
                {(status === 2) &&
                <input disabled={isUploading || !hasBeenPreviewed} className={"articleCreationButton submitArticleButton"} type="submit"
                       value="Modifier l'article"
                       onClick={() => setSubmitButton("modify")}
                />
                }
                {(user.role === 1 && status !== 2) &&
                <input disabled={isUploading || selectedSubCategories.some(ssc=> ssc.status.index === 1)} className={"articleCreationButton submitArticleButton validationButton"} type="submit"
                       value="Valider l'article"
                       onClick={() => setSubmitButton("validate")}
                />
                }
            </div>
            }
        </form>
    );
}

export default ArticleCreation;
