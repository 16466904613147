import React from "react";
import "./Confirm.css";

export default function Confirm({text="Êtes-vous sûr de vouloir supprimer cet élément ?", handleResponse}){

    function del(){
        handleResponse(true);
    }

    function cancel(){
        handleResponse(false)
    }
    return(
        <div className={"confirm-wrapper"}>
            <div onClick={()=>cancel()} className={"filter"}></div>
            <div className={"confirm tile"}>
                <p className={"confirmText"}>{text}</p>
                <div className={"confirmButtons"}>
                    <button type={"button"} onClick={()=>del()} className={"confirmButton confirmDelete"}>Oui</button>
                    <button type={"button"} onClick={()=>cancel()} className={"confirmButton confirmCancel"}>Non</button>
                </div>
            </div>
        </div>
    )
}