import React, {useEffect, useState} from "react";
import "./CustomCheckbox.css";

function CustomCheckbox({checked, onChange, disabled = false, id=null}) {
    const [uniqueID,setUniqueID] = useState("");

    useEffect(() => {
        if(id!==null)
            setUniqueID(id);
        else
            setUniqueID((performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,""))
    }, [id]);

    return(
        <span className={"customCheckboxWrapper"}>
            <input disabled={disabled} id={uniqueID} value={id !== null ? id : ""} className={"checkbox"} type="checkbox" checked={checked} readOnly={!onChange} onChange={onChange} />
            <label htmlFor={uniqueID} className={`customCheckbox ${checked===true?"activeCheckbox":""}`}>{checked===true?"✓":""}</label>
        </span>
    )
}

export default CustomCheckbox;