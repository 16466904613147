import React, {useState, useEffect} from 'react';
import './Preview.css'

export default function Preview({component, isOpen, handleClose, showClose = true, boxWrapperClass,
                                    iframe = false, manualClosing = true}) {
    const [open,setOpen] = useState(isOpen);

    useEffect(()=>{
        setOpen(isOpen)
    }, [isOpen])

    useEffect(()=>{
        if(!open)
            handleClose()
    },[open,handleClose])

    if(open){
        return (
            <div className={"previewWrapper"}>
                <div onClick={()=>{
                    if(manualClosing)
                        setOpen(false)
                }} className={`previewOverlay ${!manualClosing?"previewUnclickable":""}`}/>
                <div className={`previewBox ${boxWrapperClass?boxWrapperClass:""}`}>
                    {showClose &&
                    <button className={`previewCloseButton ${iframe?"previewAbsolute":""}`} type={"button"} onClick={() => setOpen(false)}>
                        x
                    </button>
                    }
                    {component}
                </div>
            </div>
        )
    }
    else return null;
}