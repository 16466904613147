import React, {useEffect, useState} from 'react';

import './LibraryCreation.css'
import API from "../../utils/ApiParams";
import {uploadFile} from "../../utils/Api"
import {getCategories} from "../../utils/Api";
import {Redirect} from "react-router-dom";
import BiggerImage from "../modals/BiggerImage";
import Loading from "../Loading";

function LibraryCreation(props) {
    const [imageModif,setImageModif] = useState(null);

    const [categories,setCategories] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [isUploading,setIsUploading] = useState(false);
    const [redirect,setRedirect] = useState(false);
    const [title, setTitle] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [imageDisplay, setImageDisplay] = useState([]);
    const [bigImage, setBigImage] = useState({});
    useEffect(()=>{
        async function getImage(){
            const response = await API.get("media_objects/"+props.location.state.id);
            return response.data;
        }
        setIsLoading(true);
        getCategories().then(data => setCategories(data));
        if(props.location.state){
            if (props.location.state.id) {
                getImage().then(data => {
                    setImageModif(data);
                    setTitle(data.title);
                    setSelectedCategories(data.categories);
                });
            }
        }
    }, [props.location.state]);

    useEffect(()=>{
        if(categories) {
            if (categories.length > 0)
                setIsLoading(false)
            else
                setIsLoading(true);
        }
        else
            setIsLoading(true);
    }, [categories]);

    function handleChangeTitle(event) {
        setTitle(event.target.value);
    }
    function handleChangeCategory(event) {
        let tmpCat = selectedCategories;
        if(!tmpCat.some(sc=>sc.id === parseInt(event.target.value))){
            tmpCat = [...tmpCat, categories.find(sc => sc.id === parseInt(event.target.value))];
            tmpCat.sort((a,b)=>{return a.position - b.position});
            setSelectedCategories(tmpCat);
        }
    }
    function removeCategory(selectedCategory) {
        setSelectedCategories(selectedCategories.filter(Category => Category !== selectedCategory));
    }
    function handleSubmit(event) {
        async function uploadNewImage() {
            if(isActive() && !isUploading){
                setIsUploading(true);
                if(props.location.state){
                    if (props.location.state.id) {
                        await API.patch("media_objects/"+props.location.state.id,{
                            title: title,
                            categories: selectedCategories.map(cat => "/categories/"+cat.id)
                        }, {
                            headers: {"content-type": "application/merge-patch+json"}
                        }).then(()=>setRedirect(true));
                    }
                }
                else{
                    await uploadFile(imageDisplay[0],"media_objects")
                        .then(data => API.patch("media_objects/"+data,
                            {
                                isLibrary: true,
                                categories: selectedCategories.map(cat => "/categories/"+cat.id),
                                title: title
                            },{
                                headers:{"content-type" : "application/merge-patch+json"}
                            }))
                        .then(data => setRedirect(true));
                }
            }
        }
        uploadNewImage().then(()=>setIsUploading(false));

        event.preventDefault();
    }
    function handleChangeImage(event){
        if(event.target.files.length>0){
            setImageDisplay(event.target.files)
        }
    }
    function isActive(){
        if(title && selectedCategories.length>0 && (imageDisplay[0] || imageModif !== null) && !isUploading)
            return true;
        return false;
    }
    function ImageButtonText(){
        if(imageDisplay.length>0)
            return (
                <div>
                    Modifier le visuel
                </div>
            )
        else
            return (
                <div>
                    Ajouter un visuel <span className={"add"}> +</span>
                </div>
                );
    }
    function showBigImage() {
        if(imageModif){
            setBigImage({url: process.env.REACT_APP_API_URL+"images/"+imageModif.id+"/"+imageModif.name});
        }else{
            setBigImage({url:URL.createObjectURL(imageDisplay[0])});
        }
    }
    function header() {
        let title;
        if(props.location.state){
            if (props.location.state.id) {
                title = "Édition d'une image";
            }
        } else{
            title = "Ajout d'une image";
        }
        return title;
    }
    if(redirect){
        return (<Redirect to={"/mediatheque/liste"}/>)
    }
    return (
        isLoading ? <Loading/>
        : <form className={"addImage"} onSubmit={handleSubmit}>
            <BiggerImage image={bigImage} unsetImage={()=>setBigImage({})}/>
                <div className={"col-12 flex addImageBloc"}>
                    <h2 className={"pageTitle col-12"}>{header()}</h2>
                    <div className={"imageInfo col-7 tile"}>
                        <p className={"title"}>Informations générales</p>
                        <label>
                            <p>Titre de l'image</p>
                            <input placeholder={"Ex : Boisson chaude"} className={"libraryInput"} type="text" value={title} onChange={handleChangeTitle}/>
                        </label>
                        <label>
                            <p>Catégorie</p>
                            <select className={"libraryInput customSelect"} value={""} onChange={handleChangeCategory}>
                                <option value="" disabled>Ajouter une catégorie</option>
                                {categories.map((category) => !selectedCategories.some(sc => sc.id === category.id)
                                    ?
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                    : ""
                                )}
                            </select>
                            {selectedCategories.map((selectedCategory) => (
                                <button onClick={() => removeCategory(selectedCategory)} className={`clickableCategoryButton category-${selectedCategory.id}-full category-${selectedCategory.id}-hover`}
                                        key={selectedCategory.id}>{selectedCategory.name} <span className={"delete"}>✕</span></button>
                            ))}
                        </label>
                    </div>
                    <div className={"imageCover col tile"}>
                        {(imageDisplay.length > 0 || imageModif !== null) &&
                        <img onClick={()=> showBigImage()} className={"image"}
                             src={imageDisplay.length > 0
                                 ? URL.createObjectURL(imageDisplay[0])
                                 : process.env.REACT_APP_API_URL+"images/"+imageModif.id+"/"+imageModif.name} alt=""/>
                        }
                        {imageModif === null &&
                            <label className={"buttonImageInput"}>
                                <ImageButtonText/>
                                <input className={"imageInput"} type="file" accept="image/*" onChange={handleChangeImage}/>
                            </label>
                        }
                    </div>
                    <div className={"col-12 submit"}>
                        <input className={"submitButton"} disabled={!isActive()} type="submit" value="Enregistrer l'image"/>
                    </div>
                </div>
        </form>
    );
}

export default LibraryCreation;
