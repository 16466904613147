import React from "react";
import "./ArticlePreview.css";

// Expected fields of article --> categories, subCategories, title, author, creationDate, text
export default function ArticlePreview({article}) {
    return(
        <div className={"articleContentWrapper"}>
            <div className={"articleCategories"}>
                {article.categories.map(category=>{
                    return(
                        <div key={category.id} className={`articleCategory category-${category.id}-border category-${category.id}-full`}>
                            {category.name}
                        </div>
                    )
                })}
                {article.subCategories.map(subcategory=>{
                    return(
                        <div key={subcategory.id} className={`articleCategory articleSubcategory`}>
                            {subcategory.name}
                        </div>
                    )
                })}
            </div>
            <div className={"articleTitleBar"}>
                <div className={`articleTitle category-${article.categories[0].id}-text`}>
                    {article.title}
                </div>
            </div>
            <div className={"articleDate"}>
                Par {article.author.name} le {new Date(article.creationDate).toLocaleDateString('fr-FR',{ year: 'numeric', month: 'long', day: 'numeric' })}
            </div>
            <div className={"articleText"} dangerouslySetInnerHTML={{__html: article.shortText}}/>
            <div className={"articleText"} dangerouslySetInnerHTML={{__html: article.text}}/>
        </div>
    )
}