import React from "react";
import "./Pagination.css";

function Pagination({currentPage, pageElement, totalElements, pageNeighbours = 1, handleChangePage}) {
    const pages = Math.ceil(totalElements/pageElement);
    const pagesNumber = [...Array(pages)].map((e,i)=>(
       e = i+1
    ));
    const filteredPagesNumber = pagesNumber.filter((index)=>(filterPages(index)));
    function RenderPrevious() {
        return(
            <button type="button" className={"page"} onClick={handlePagePrevious}>&#x3C;</button>
        );
    }
    function RenderFirst() {
        if(pagesNumber.length>0)
            return(
                <button type="button" className={`page ${currentPage === pagesNumber[0] ? "active" : ""}`}
                        onClick={() => handlePage(pagesNumber[0])}>
                    {pagesNumber[0]}
                </button>
            );
        else return(
            <button type="button" className={`page ${currentPage === 1 ? "active" : ""}`}
                    onClick={() => handlePage(1)}>
                {1}
            </button>
        )
    }
    function RenderFirstDots(){
        if(filteredPagesNumber[0] !== pagesNumber[1] && filteredPagesNumber.length>1){
            return(
                <button type="button" className={"page inactive"}>...</button>
            )
        }
        else return null;
    }
    function RenderLastDots(){
        if(filteredPagesNumber[filteredPagesNumber.length-1] !== pages-1 && filteredPagesNumber.length>1){
            return(
                <button type="button" className={"page inactive"}>...</button>
            )
        }
        else return null;
    }
    function RenderLast() {
        if(pagesNumber.length>1)
            return(
                <button type="button" className={`page ${currentPage === pages ? "active" : ""}`} onClick={() => handlePage(pages)}>{pages}</button>
            );
        else return null;
    }
    function RenderNext() {
            return(
                <button type="button" className={"page"} onClick={handlePageNext}>&#x3E;</button>
            );
    }
    function handlePagePrevious() {
        if(currentPage > 1)
            handleChangePage(currentPage-1);
    }
    function handlePageNext() {
        if(currentPage < pages)
            handleChangePage(currentPage+1);
    }
    function handlePage(i) {
        handleChangePage(i);
    }

    function filterPages(i) {
        let left = 0;
        let right = 0;
        if (currentPage - pageNeighbours <= 1)
            right = Math.abs(currentPage - pageNeighbours - 1)+1;
        if (currentPage + pageNeighbours >= pages)
            left = currentPage + pageNeighbours - pages+1;

        if ((i >= currentPage - pageNeighbours - left) && (i <= currentPage + pageNeighbours + right) && i!==1 && i!==pages)
            return true
        return false;
    }

    return(
        <div>
            <RenderPrevious/>
            <RenderFirst/>
            <RenderFirstDots/>
            {filteredPagesNumber.map((i) => (
                <button type="button" key={i} className={`page ${currentPage === i ? "active" : ""}`}
                        onClick={() => handlePage(i)}>
                    {i}
                </button>
            ))}
            <RenderLastDots/>
            <RenderLast/>
            <RenderNext/>
        </div>
    )
}

export default Pagination;