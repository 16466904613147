export function FilterOr(arr, criteria) {
    return arr.filter(function(obj) {
        return Object.keys(criteria).some(function(c) {
            if(obj[c].value){
                if (Array.isArray(obj[c].value)) {
                    if(obj[c].value.length === 0 && criteria[""])
                        return true;
                    let values = null;
                    if(obj[c].value[0].value)
                        values = obj[c].value.map(x=>x.value);
                    else
                        values = obj[c].value.map(x => x);
                    return values.some(value => value.includes(criteria[c]));
                }
                return obj[c].value.toUpperCase().includes(criteria[c].toUpperCase());
            }
            if(obj[c] instanceof Date){
                if (isNaN(criteria[c]))
                    return true;
                obj[c].setHours(0,0,0,0);
                criteria[c].setHours(0,0,0,0);
                return obj[c].getTime() === criteria[c].getTime();
            }
            return obj[c].toUpperCase().includes(criteria[c].toUpperCase());
        });
    });
}

export function FilterAnd(arr, criteria) {
    return arr.filter(function(obj) {
        return Object.keys(criteria).every(function(c) {
            if(criteria[c] === "")
                return true;
            if(obj[c].value){
                if (Array.isArray(obj[c].value)) {
                    if(obj[c].value.length === 0 && criteria[""])
                        return true;
                    let values = null;
                    if(obj[c].value[0].value)
                        values = obj[c].value.map(x=>x.value);
                    else
                        values = obj[c].value.map(x => x);
                    return values.some(value => value.includes(criteria[c]));
                }
                return obj[c].value.toUpperCase().includes(criteria[c].toUpperCase());
            }
            if(obj[c] instanceof Date){
                if (isNaN(criteria[c]))
                    return true;
                obj[c].setHours(0,0,0,0);
                criteria[c].setHours(0,0,0,0);
                return obj[c].getTime() === criteria[c].getTime();
            }
            return obj[c].toUpperCase().includes(criteria[c].toUpperCase());
        });
    });
}