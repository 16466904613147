import slugify from "slugify";
import React from "react";

export default function generateMailHtml(articles, coverUrl, flash){
    articles.sort((a,b)=>{
        return a.categories[0].position - b.categories[0].position;
    })
    return(
        <html>
        <head>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans&display=swap" rel="stylesheet" type="text/css"/>
            <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet" type="text/css"/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" type="text/css"/>
            <style type={"text/css"} dangerouslySetInnerHTML={{__html: `
                              html,body{
                                font-family: "DM Sans","Lato","Montserrat",Helvetica;
                              }
                              *{
                                font-family: "DM Sans","Lato","Montserrat",Helvetica;
                              }
                              .mailContainer{
                                max-width:590px !important;    
                                width:100%;
                                background-color: white;
                                margin: auto;
                              }
                              .textHeader{
                                font-family: "DM Sans","Lato","Montserrat",Helvetica;
                                padding-top: 20px;
                              }
                              .logoWrapper{
                                text-align: center;
                                padding-bottom: 15px !important;
                              }
                              .logo{    
                               
                              }
                              .bannerImg{
                                object-fit:cover;

                              }
                              .bannerText{
                                background-color: #DC052C;
                                width: 100%;
                                padding-left:20px !important;
                              }
                              .mailMontserrat{
                                font-family: 'Montserrat',"DM Sans","Lato",Helvetica;
                                letter-spacing: 0px !important;
                              }
                              .mailTitle{    
                                font-weight: normal;
                                font-size: 22px;
                                line-height: 30px;
                                color:white;
                                margin:0;
                                letter-spacing: 1px;
                              }
                              .mailTitleBold{
                                font-family: "Lato","DM Sans",'Montserrat',Helvetica !important;
                                font-weight: bold;
                              }
                              .colorBlack{
                                color : black;
                              }
                              .mailDate{
                                color:white;
                                font-size: 10px;
                                line-height: 12px;
                              }
                              .mailArticleTable{
                                table-layout:fixed;
                                border-collapse: collapse; 
                                border-spacing: 0 20px;
                                max-width: 530px;
                                margin: auto;
                              }
                              .mailArticleImg{
                                
                                object-fit:cover;
                              }

                              .mailArticleLeft{
                                padding : 20px 15px 15px 0 !important;
                              }
                              .mailArticleRight{
                                 padding: 20px 0 15px 15px !important;
                              }
                              .mailCategoryTitle{
                                width: 90%;
                                font-family: "Lato","DM Sans",'Montserrat',Helvetica !important;
                                font-size: 22px;
                                font-weight: 900;
                                line-height:26px;
                                margin-top: 10px;
                                margin-bottom: 15px;
                              }
                              .mailText{
                                width : 100%;
                                margin-bottom:10px;
                                font-size:14px;
                              }
                              .mailCategoryList{
                                margin: 0;
                                padding: 0;
                              }
                              .mailCategory{
                                width: fit-content;
                                color:white;
                                font-size:12px;
                                line-height:12px;
                                height:12px;
                                padding: 3px 4px !important;
                                text-transform: uppercase;
                                margin : 0 5px 5px 0;
                                display: inline-block;
                              }
                              .mailCategory-1{
                                background-color: ${process.env.REACT_APP_CATEGORY_1_COLOR};
                              }
                              .mailCategory-2{
                                background-color: ${process.env.REACT_APP_CATEGORY_2_COLOR};
                              }
                              .mailCategory-3{
                                background-color: ${process.env.REACT_APP_CATEGORY_3_COLOR};
                              }
                              .mailCategory-4{
                                background-color: ${process.env.REACT_APP_CATEGORY_4_COLOR};
                              }
                              .mailCategory-5{
                                background-color: ${process.env.REACT_APP_CATEGORY_5_COLOR};
                              }                                                        
                              .mailCategory-1-color{
                                color: ${process.env.REACT_APP_CATEGORY_1_COLOR};
                              }
                              .mailCategory-2-color{
                                color: ${process.env.REACT_APP_CATEGORY_2_COLOR};
                              }
                              .mailCategory-3-color{
                                color: ${process.env.REACT_APP_CATEGORY_3_COLOR};
                              }
                              .mailCategory-4-color{
                                color: ${process.env.REACT_APP_CATEGORY_4_COLOR};
                              }
                              .mailCategory-5-color{
                                color: ${process.env.REACT_APP_CATEGORY_5_COLOR};
                              }
                              .mailCategory-button{
                                background-color: white;
                                font-size: 12px;
                                line-height: 12px;
                                text-align:center;
                                text-transform: uppercase;
                                padding : 2px 15px !important;
                                text-decoration: none;
                                margin-right: 10px;
                              }
                              .mailCategory-1-button{
                                border: 2px solid ${process.env.REACT_APP_CATEGORY_1_COLOR};
                                color: ${process.env.REACT_APP_CATEGORY_1_COLOR} !important;
                              }
                              .mailCategory-2-button{
                                border: 2px solid ${process.env.REACT_APP_CATEGORY_2_COLOR};
                                color: ${process.env.REACT_APP_CATEGORY_2_COLOR} !important;
                              }
                              .mailCategory-3-button{
                                border: 2px solid ${process.env.REACT_APP_CATEGORY_3_COLOR};
                                color: ${process.env.REACT_APP_CATEGORY_3_COLOR} !important;
                              }
                              .mailCategory-4-button{
                                border: 2px solid ${process.env.REACT_APP_CATEGORY_4_COLOR};
                                color: ${process.env.REACT_APP_CATEGORY_4_COLOR} !important;
                              }
                              .mailCategory-5-button{
                                border: 2px solid ${process.env.REACT_APP_CATEGORY_5_COLOR};
                                color: ${process.env.REACT_APP_CATEGORY_5_COLOR} !important;
                              }               
                              .mailArticleLink{
                                display: inline-block;
                              }
                              .mailAuthor{
                                color: grey;
                                font-size: 12px;
                                margin-bottom:10px;
                              }
                              .mailComment{
                                
                                vertical-align: middle;
                              }
                              .mailFooterTable{
                                table-layout:fixed;
                              }                  
                              .mailFooterSub{
                                height: 80px;
                                background-color: #DC052C;
                              }           
                              .mailFooterCell{
                                text-align:center;
                              }
                              .mailLink{
                                color: white !important;
                                background-color:transparent;
                                font-size: 13px;
                                text-decoration: none;
                              }
                              .mailFooterCopyright{
                                height: 80px;
                                background-color: #8F8278;
                              }                          
                              .mailCopyright{
                                color: white;
                                font-size: 13px;
                              }
                            `}}
            />
        </head>
        <body className={"mailContainer"}
         style={{
             maxWidth:"590px !important",
             width:"100%",
             backgroundColor:"white",
             margin: "auto",
             msoLineHeightRule: "exactly",
         }}
        >
        <table cellSpacing="0" cellPadding="0" border="0" width="590px" className={"mailContainer"}
               style={{
                   maxWidth:"590px !important",
                   width:"100%",
                   backgroundColor:"white",
                   margin: "auto",
                   msoTableLspace:"0pt",
                   msoTableRspace:"0pt",
               }}
               align={"center"}
        >
            <tbody>
                <tr>
                    <td>
                        <table cellSpacing="0" cellPadding="0" border="0" width="100%"
                               align={"center"}
                        style={{
                            msoTableLspace:"0pt",
                            msoTableRspace:"0pt",
                        }}
                        >
                            <tbody>
                                <tr style={{marginBottom:"0 !important", paddingBottom:"0 !important"}}>
                                    {/*${process.env.REACT_APP_API_URL}
                                ${process.env.REACT_APP_API_URL}mail/flash.png}
                                */}
                                    <td colSpan={flash?1:2} className={"logoWrapper"} style={flash?{marginBottom:"0 !important", paddingBottom:"0 !important"}:{}}>
                                        <img style={{width:"135px"}} width={"135"} src={`${process.env.REACT_APP_API_URL}mail/Logo.png`} alt="Logo" className={"logo"}/>
                                    </td>
                                    {flash &&
                                    <td colSpan={1} className={"logoWrapper"} style={flash?{marginBottom:"0 !important", paddingBottom:"0 !important"}:{}}>
                                        <img style={{width:"auto", height:"75px"}} width={"auto"} height={"75"}
                                             src={`${process.env.REACT_APP_API_URL}mail/flash.png`} alt="Logo"/>
                                    </td>
                                    }
                                </tr>
                                <tr className={"empty"} style={{lineHeight:"0px"}}>
                                    <td colSpan={flash?2:1}>
                                        <img style={flash?{width:"590px",height:"200px", objectFit:"cover"}
                                                        :{width:"380px",height:"200px", objectFit:"cover"}}
                                             width={flash?"590":"380"} height={"200"} src={coverUrl} alt="" className={"bannerImg"}/>
                                    </td>
                                    {!flash &&
                                    <td className={"bannerText"}>
                                        <h1 className={"mailTitle"}>
                                            <div className={"mailMontserrat"}>INFORMATIONS</div>
                                            <div className={"mailTitleBold"}>DE LA</div>
                                            <div style={{color: "black"}}
                                                 className={"mailTitleBold colorBlack"}>SEMAINE
                                            </div>
                                        </h1>
                                        <div className={"mailDate"}>{new Date().toLocaleString("fr-FR", {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit"
                                        })}
                                        </div>
                                    </td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing="0" cellPadding="0" border="0" width="530px" className={"mailArticleTable"}
                            style={{
                                tableLayout:"fixed",
                                borderCollapse: "collapse",
                                borderSpacing: "0 20px",
                                maxWidth: "530px",
                                width:"530px",
                                margin: "auto",
                                msoTableLspace:"0pt",
                                msoTableRspace:"0pt",
                            }}
                           align={"center"}
                        >
                            <tbody>
                            {!flash &&
                            <tr>
                                <td colSpan={2} className={"textHeader"}>
                                    Bonjour, retrouvez toutes les dernières informations du groupement dans cette newsletter.

                                </td>
                            </tr>
                            }
                            {articles.map((art, index) => {
                                let color;
                                let count = 0;

                                switch (art.categories[0].id) {
                                    case 1 :
                                        color = process.env.REACT_APP_CATEGORY_1_COLOR;
                                    break;
                                    case 2 :
                                        color = process.env.REACT_APP_CATEGORY_2_COLOR;
                                    break;
                                    case 3 :
                                        color = process.env.REACT_APP_CATEGORY_3_COLOR;
                                    break;
                                    case 4 :
                                        color = process.env.REACT_APP_CATEGORY_4_COLOR;
                                    break;
                                    case 5 :
                                        color = process.env.REACT_APP_CATEGORY_5_COLOR;
                                    break;
                                    default:
                                        color = process.env.REACT_APP_CATEGORY_1_COLOR;
                                        break;
                                }
                                if (index % 2 === 0){
                                    return (
                                        <>
                                            <tr>
                                                <td colSpan={2} style={index !== 0
                                                    ? {backgroundColor : "#8F8278", height: "2px"}
                                                    : {}
                                                }>
                                                </td>
                                            </tr>
                                            <tr key={art.id}>
                                                <td  className={"mailArticleLeft"}
                                                    style={{
                                                        padding: "20px 15px 15px 0 !important",
                                                    }}
                                                >
                                                    {/* Remplacer le lien*/}
                                                    <img className={"mailArticleImg"}
                                                         style={{width:"250px",height:"250px", objectFit: "cover"}}
                                                         height={"250"}
                                                         width={"250"}
                                                         src={art.coverUrl}
                                                         alt=""/>
                                                </td>
                                                <td className={"mailArticleRight"}
                                                style={{
                                                    padding: "20px 0 15px 15px !important",
                                                }}>
                                                    <div className={"mailCategoryList"}
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth:"265px",
                                                        }}
                                                    >
                                                        {art.categories.map((category,indexCat)=>{
                                                            let catColor;
                                                            if(indexCat === 0){
                                                                count = 0;
                                                            }
                                                            if(count >= 30)
                                                                count = art.categories[indexCat-1].name.length;
                                                            count+= category.name.length;
                                                            switch (category.id) {
                                                                case 1 :
                                                                    catColor = process.env.REACT_APP_CATEGORY_1_COLOR;
                                                                    break;
                                                                case 2 :
                                                                    catColor = process.env.REACT_APP_CATEGORY_2_COLOR;
                                                                    break;
                                                                case 3 :
                                                                    catColor = process.env.REACT_APP_CATEGORY_3_COLOR;
                                                                    break;
                                                                case 4 :
                                                                    catColor = process.env.REACT_APP_CATEGORY_4_COLOR;
                                                                    break;
                                                                case 5 :
                                                                    catColor = process.env.REACT_APP_CATEGORY_5_COLOR;
                                                                    break;
                                                                default:
                                                                    catColor = process.env.REACT_APP_CATEGORY_1_COLOR;
                                                                    break;
                                                            }
                                                            return (
                                                                <>
                                                                    {count >=30 &&

                                                                    <><br/>
                                                                        <table style={{
                                                                            borderSpacing:0,
                                                                        }}>
                                                                            <tr>
                                                                                <td style={{
                                                                                    backgroundColor: "transparent",
                                                                                    height:"0px",
                                                                                }}>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </>
                                                                    }
                                                                    <span key={category.id}>
                                                                            {(count < 30 && indexCat !==0) &&
                                                                            <>&nbsp;</>
                                                                            }
                                                                <span className={`mailCategory mailCategory-${category.id}`}
                                                                    style={{
                                                                        width: "fit-content",
                                                                        color:"white",
                                                                        fontSize:"11px",
                                                                        lineHeight:"11px",
                                                                        height:"12px",
                                                                        padding: "3px 4px !important",
                                                                        textTransform: "uppercase",
                                                                        margin : "0 5px 5px 0",
                                                                        display: "inline-block",
                                                                        backgroundColor: catColor,
                                                                    }}
                                                                >&nbsp;{category.name}&nbsp;
                                                                    </span>
                                                                </span>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className={`mailCategory-${art.categories[0].id}-color mailCategoryTitle`}
                                                    style={{
                                                        width: "90%",
                                                        fontFamily: `"Lato","DM Sans",'Montserrat',Helvetica !important`,
                                                        fontSize: "22px",
                                                        fontWeight: "900",
                                                        lineHeight:"26px",
                                                        marginTop: "10px",
                                                        marginBottom: "15px",
                                                    }}
                                                    >
                                                        {art.title}
                                                    </div>
                                                    <div className={"mailText"} dangerouslySetInnerHTML={{__html:art.text}}>
                                                    </div>
                                                    <div className={"mailAuthor"}
                                                         style={{
                                                             color: "grey",
                                                             fontSize: "12px",
                                                             marginBottom:"10px",
                                                         }}
                                                    >
                                                        Écrit par {art.author}
                                                    </div>
                                                    <table>
                                                    <tr className={"mailArticleLink"}
                                                         style={{
                                                        display: "inline-block",
                                                    }}>
                                                        <td>
                                                            <a href={`${process.env.REACT_APP_SITE_URL}article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`}
                                                               className={`mailCategory-button mailCategory-${art.categories[0].id}-button`}
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    fontSize: "12px",
                                                                    lineHeight: "12px",
                                                                    textAlign:"center",
                                                                    textTransform: "uppercase",
                                                                    padding : "2px 15px !important",
                                                                    textDecoration: "none",
                                                                    marginRight: "10px",
                                                                    color: color,
                                                                    border : `2px solid ${color}`
                                                                }}
                                                            >
                                                                &nbsp;Lire l'article&nbsp;
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href={`${process.env.REACT_APP_SITE_URL}article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`}>
                                                                {/* Ajouter image dans l'API et remplacer le lien*/}
                                                                <img style={{width:"20px",height:"20px",verticalAlign: "middle"}} width={"20"} height={"20"}
                                                                     className={"mailComment"} src={`${process.env.REACT_APP_API_URL}mail/Chat.png`} alt=""/>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }else{
                                    return (
                                        <>
                                        <tr >
                                            <td colSpan={2} style={index !== 0
                                                ? {backgroundColor : "#8F8278", height: "2px"}
                                                : {}
                                            }>
                                            </td>
                                        </tr>
                                        <tr key={art.id}>
                                            <td className={"mailArticleLeft"}
                                                style={{
                                                    padding : "20px 15px 15px 0 !important",
                                                }}>
                                                <div className={"mailCategoryList"}
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        listStyle: "none",
                                                    }}
                                                >
                                                    {art.categories.map((category,indexCat)=>{
                                                        let catColor;
                                                        if(indexCat === 0){
                                                            count = 0;
                                                        }
                                                        if(count >= 30)
                                                            count = art.categories[indexCat-1].name.length;
                                                        count+= category.name.length;
                                                        switch (category.id) {
                                                            case 1 :
                                                                catColor = process.env.REACT_APP_CATEGORY_1_COLOR;
                                                                break;
                                                            case 2 :
                                                                catColor = process.env.REACT_APP_CATEGORY_2_COLOR;
                                                                break;
                                                            case 3 :
                                                                catColor = process.env.REACT_APP_CATEGORY_3_COLOR;
                                                                break;
                                                            case 4 :
                                                                catColor = process.env.REACT_APP_CATEGORY_4_COLOR;
                                                                break;
                                                            case 5 :
                                                                catColor = process.env.REACT_APP_CATEGORY_5_COLOR;
                                                                break;
                                                            default:
                                                                catColor = process.env.REACT_APP_CATEGORY_1_COLOR;
                                                                break;
                                                        }
                                                        return (
                                                            <>
                                                                {count >=30 &&
                                                                <>
                                                                    <br/>
                                                                    <table style={{
                                                                        borderSpacing:0,
                                                                    }}>
                                                                        <tr>
                                                                            <td style={{
                                                                                backgroundColor: "transparent",
                                                                                height:"0px",
                                                                            }}>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </>
                                                                }
                                                                <span key={category.id}>
                                                                    {(count < 30 && indexCat !==0) &&
                                                                    <>&nbsp;</>
                                                                    }
                                                                <span  className={`mailCategory mailCategory-${category.id}`}
                                                                      style={{
                                                                          width: "fit-content",
                                                                          color:"white",
                                                                          fontSize:"11px",
                                                                          lineHeight:"11px",
                                                                          height:"12px",
                                                                          padding: "3px 4px !important",
                                                                          textTransform: "uppercase",
                                                                          margin : "0 5px 5px 0",
                                                                          display: "inline-block",
                                                                          backgroundColor: catColor,
                                                                      }}
                                                                >&nbsp;{category.name}&nbsp;
                                                                    </span>

                                                                </span>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div className={`mailCategory-${art.categories[0].id}-color mailCategoryTitle`}
                                                     style={{
                                                         width: "90%",
                                                         fontFamily: `"Lato","DM Sans",'Montserrat',Helvetica !important`,
                                                         fontSize: "22px",
                                                         fontWeight: "900",
                                                         lineHeight:"26px",
                                                         marginTop: "10px",
                                                         marginBottom: "15px",
                                                     }}
                                                >
                                                    {art.title}
                                                </div>
                                                <div className={"mailText"} dangerouslySetInnerHTML={{__html:art.text}}>
                                                </div>
                                                <div className={"mailAuthor"}
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "12px",
                                                        marginBottom:"10px",
                                                    }}
                                                >
                                                    Écrit par {art.author}
                                                </div>
                                                <table>
                                                    <tr className={"mailArticleLink"}
                                                        style={{
                                                            display: "inline-block",
                                                        }}>
                                                        <td>
                                                            <a href={`${process.env.REACT_APP_SITE_URL}article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`}
                                                               className={`mailCategory-button mailCategory-${art.categories[0].id}-button`}
                                                               style={{
                                                                   backgroundColor: "white",
                                                                   fontSize: "12px",
                                                                   lineHeight: "12px",
                                                                   textAlign:"center",
                                                                   textTransform: "uppercase",
                                                                   padding : "2px 15px !important",
                                                                   textDecoration: "none",
                                                                   marginRight: "10px",
                                                                   color: color,
                                                                   border : `2px solid ${color}`
                                                               }}
                                                            >
                                                                &nbsp;Lire l'article&nbsp;
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href={`${process.env.REACT_APP_SITE_URL}article/${slugify(art.title+" "+art.id,{lower:true, strict:true})}`}>
                                                                <img style={{width:"20px",height:"20px",verticalAlign: "middle"}} width={"20"} height={"20"}
                                                                     className={"mailComment"} src={`${process.env.REACT_APP_API_URL}mail/Chat.png`} alt=""/>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td className={"mailArticleRight"}
                                                    style={{
                                                        padding: "20px 0 15px 15px !important",
                                                    }}
                                                    >
                                                <img className={"mailArticleImg"}
                                                     style={{width:"250px",height:"250px", objectFit: "cover"}}
                                                     height={"250"}
                                                     width={"250"}
                                                     src={art.coverUrl}
                                                     alt=""/>
                                            </td>
                                        </tr>
                                        </>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                        <table cellSpacing="0" cellPadding="0" border="0" width="100%" className={"mailFooterTable"}>
                            <tbody>
                                <tr className={"mailFooterSub"}>
                                    <td className={"mailFooterCell"}>
                                        <a className={"mailLink"} href={`${process.env.REACT_APP_SITE_URL}authentification/register`}>
                                            Inscrire un collaborateur
                                        </a>
                                    </td>
                                    <td className={"mailFooterCell"}>
                                        <a className={"mailLink"} href="{{ unsubscribe }}">
                                            Se désinscrire
                                        </a>
                                    </td>
                                </tr>
                                <tr className={"mailFooterCopyright"}>
                                    <td colSpan={2} className={"mailFooterCell"}>
                                        <div className={"mailCopyright"}>
                                            Ces informations sont confidentielles et ne doivent pas être divulguées
                                        </div>
                                        <div className={"mailCopyright"}>
                                            en dehors du réseau des adhérents DISTRIBOISSONS
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        </body>
        </html>
    )
}