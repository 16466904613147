import React, {useEffect, useState, useContext} from "react";
import Table from "../Table";
import {FilterAnd} from "../../utils/FilterList";
import Pagination from "../Pagination";
import {getCategories, getSubCategories} from "../../utils/Api";
import "./ArticleList.css"
import {Redirect} from "react-router-dom";
import API from '../../utils/ApiParams'
import UserContext from "../../contexts/UserContext";
import Confirm from "../modals/Confirm";
import Preview from "../modals/Preview";
import ArticlePreview from "./ArticlePreview";
import Loading from "../Loading";

function ArticleList(props) {

    const user = useContext(UserContext);
    const [toArticleEdition,setToArticleEdition] = useState({navigate: false,id: null});
    const [articles,setArticles] = useState([]);
    const [articlesLoaded,setArticlesLoaded] = useState(false);
    const [categories,setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesLoaded,setSubCategoriesLoaded] = useState(false);

    const [authors, setAuthors] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [shownArticlesNumber,setShownArticlesNumber] = useState(5);
    const [searched,setSearched] = useState("");
    const [searchedCategory,setSearchedCategory] = useState("");
    const [searchedSubCategory,setSearchedSubCategory] = useState("");
    const [searchedAuthor,setSearchedAuthor] = useState("");
    const [page, setPage] = useState(1);

    const [delArticleId, setDelArticleId] = useState(null);
    const [openConfirm,setOpenConfirm] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewedArticle, setPreviewedArticle] = useState({})

    function copyArticle(id) {
        setToArticleEdition({navigate:true, id: id, action:"duplicate"});
    }
    function modifyArticle(id) {
        setToArticleEdition({navigate:true, id: id, action:"edit"});
    }
    async function deleteArticle(id) {
        setOpenConfirm(true);
        setDelArticleId(id);
    }
    async function confirmDelete(confirm) {
        setOpenConfirm(false);
        if (confirm){
            const response = await API.patch("articles/"+delArticleId,
                {
                    isArchived: true
                },
                {headers: {'Content-Type': 'application/merge-patch+json'}});
            if(response.status === 200){
                setArticles(articles => articles.filter(art => parseInt(art.id) !== delArticleId))
                props.reload();
            }
        }
    }
    function previewArticle(id) {
        async function getPreviewedArticle() {
            const response = await API.get(`articles/${id}`);
            return response.data;
        }
        getPreviewedArticle().then(data=>{
            setPreviewedArticle(data);
        })
    }
    useEffect(()=>{
        if(Object.keys(previewedArticle).length>0){
            setPreviewOpen(true);
        }
    },[previewedArticle])
    useEffect(()=>{
        if(articles && categories && subCategories && authors) {
            if ((articles.length > 0 || articlesLoaded) && categories.length > 0 && (subCategories.length > 0 || subCategoriesLoaded) && authors.length > 0)
                setIsLoading(false)
            else
                setIsLoading(true);
        }
        else
            setIsLoading(true);
    }, [articles,categories,subCategories, authors, articlesLoaded])

    useEffect(()=>{
async function getAuthors(){
            const response = await API.get("users?access%5Bbetween%5D=1..2&order%5Bname%5D=asc");
            //Should add isArchived=false, but it needs to be added in the API aswell
            let filteredAuthors = response.data.filter(author => author.isArchived === false);
            
            return filteredAuthors;
        }
        getCategories().then(data => setCategories(data));
        getSubCategories().then(data => {
            setSubCategories(data);
            setSubCategoriesLoaded(true);
        });
        getAuthors().then(data => setAuthors(data));

    }, [])
    useEffect(()=>{
        async function getArticles() {
            let url = "articles?isArchived=false&order%5BcreationDate%5D=desc&order%5Bcategories.position%5D=asc";
            if(props.location.state){
                if(props.location.state.validated)
                    url += "&status.index=2"
                else
                    url += "&status.index=1"
            }
            const response = await API.get(url);

            return response.data.map(article=>{
                let notif = 1;
                if(article.subCategories.some(sc => sc.status.index === 1))
                    notif++;
                return (
                {title : {
                        format: "img",
                        url : `${process.env.REACT_APP_API_URL}images/${article.thumbnail ? article.thumbnail.id : article.coverImage.id}/${article.thumbnail ? article.thumbnail.name : article.coverImage.name}`,
                        className : "tableImg",
                        value : article.title
                    },
                    category: {format: "list", value : article.categories.map(category=>(
                            {value: category.name, className: "category-"+category.id}
                        ))},
                    subCategory: {format: "list", value : article.subCategories.length>0
                            ? article.subCategories.map(subCategory=>(
                            {value: subCategory.name}
                            )) : [{value: " "}]},
                    author: {format: "txt",value : article.author.name},
                    creationDate : {format: "date",value : new Date(article.creationDate)},
                    modificationDate : {format: "date",value : article.modificationDate?new Date(article.modificationDate):null},
                    action : {format: "button", id : article.id, value : [
                            (user.role === 1 || user.id === article.author.id ) &&{handleClick: modifyArticle, text: "Modifier", className : "actionButton modifyButton"},
                            {handleClick: copyArticle, text: "Dupliquer", className : "actionButton duplicateButton"},
                            user.role === 1 && {handleClick: deleteArticle, text: "Supprimer", className : "actionButton deleteButton"},
                            {handleClick: previewArticle, text: "Prévisualiser", className : "actionButton previewButton"},
                        ]},
                    status : {format: "status",value : article.status.index, notifications: notif},
                    newsletter : {format: "list",value : article.newsletters.length>0
                            ? article.newsletters.map(newsletters=>(
                                {value: newsletters.id}
                            )) : [{value: null}]},
                    id:article.id,
                    defaultAction: (user.role === 1 || user.id === article.author.id ) && {handleClick:modifyArticle, id:article.id},
                    rowClassName: article.status.index === 1 ? "requireAction" : ""
                }
            )})
        }
        getArticles().then(data => {
            setArticlesLoaded(true);
            setArticles(data);
        });
    }, [user,props.location.state])



    function handleChangeNbArticles(event){
        if(event.target.value>0){
            setShownArticlesNumber(event.target.value);
            handleChangePage(1);
        }
    }
    function handleChangeSearch(event){
        setSearched(event.target.value);
    }
    function handleChangeSearchCategory(event){
        setSearchedCategory(event.target.value);
        setSearchedSubCategory("");
    }
    function handleChangeSearchSubCategory(event){
        setSearchedSubCategory(event.target.value);
    }
    function handleChangeSearchAuthor(event){
        setSearchedAuthor(event.target.value);
    }
    function handleChangePage(newPage){
        setPage(newPage);
    }
    function resetFilters(){
        setSearched("");
        setSearchedCategory("");
        setSearchedSubCategory("");
        setSearchedAuthor("");
    }
    useEffect(() => {
        setFilteredArticles(FilterAnd(articles,
            {title: searched, category: searchedCategory, subCategory: searchedSubCategory, author: searchedAuthor}));
        handleChangePage(1);
    }, [searched,searchedCategory,searchedSubCategory,searchedAuthor,articles]);

    if (toArticleEdition.navigate){
        return (
            <Redirect push to={{
                pathname:"/articles/creation",
                state: {id : toArticleEdition.id, action : toArticleEdition.action}
            }}/>
        )
    }

    return (
        isLoading ? <Loading/>
        : <div className={"articlesList"}>
            {openConfirm &&
                <Confirm handleResponse={confirmDelete}/>
            }
            { previewOpen &&
            <Preview isOpen={previewOpen} handleClose={()=>setPreviewOpen(false)}
                     component={<ArticlePreview article={previewedArticle}/>}
                     boxWrapperClass={"previewOverflow"}/>
            }
            <main className={"main"}>
                <div className={"filterBar"}>
                    <div className={"filterNumber"}>
                        <input className={"inputNumber"} type="number" value={shownArticlesNumber}
                               onChange={handleChangeNbArticles}/>
                        <span> enregistrements par page</span>
                    </div>
                    <input className={`customInput ${searched !== "" ? "activeFilter" : ""}`} placeholder={"Rechercher"}
                           type="text" value={searched} onChange={handleChangeSearch}/>
                    <select className={`customInput customSelect ${searchedCategory !== "" ? "activeFilter" : ""}`}
                            value={searchedCategory} onChange={handleChangeSearchCategory}>
                        <option value="" disabled>Catégorie</option>
                        <option value="">Toutes</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    <select className={`customInput customSelect ${searchedSubCategory !== "" ? "activeFilter" : ""}`}
                            value={searchedSubCategory} onChange={handleChangeSearchSubCategory}>
                        <option value="" disabled>Sous-catégorie</option>
                        <option value="">Toutes</option>
                        {subCategories.map((subCategory) => (
                            subCategory.category.name === searchedCategory ?
                                <option key={subCategory.id} value={subCategory.name}>{subCategory.name}</option>
                                : ""
                        ))}
                    </select>
                    <select className={`customInput customSelect ${searchedAuthor !== "" ? "activeFilter" : ""}`}
                            value={searchedAuthor} onChange={handleChangeSearchAuthor}>
                        <option value="" disabled>Auteur</option>
                        <option value="">Tous</option>
                        {authors.map((author) => (
                            <option key={author.id} value={author.name}>{author.name}</option>
                        ))}
                    </select>
                    <button className={"resetFiltersButton"} onClick={resetFilters}>
                        Réinitialiser les filtres
                    </button>
                </div>
                <Table headers={{
                    title: "Titre",
                    category: "Catégorie",
                    subCategory: "Sous cat.",
                    author: "Auteur",
                    creationDate: "Date de création",
                    modificationDate: "Date de modif.",
                    action: "Action",
                    status: "Statut",
                    newsletter: "Newsletter",
                }}
                       elements={filteredArticles}
                       currentPage={page}
                       elementsPerPage={shownArticlesNumber}/>
            </main>
            <div className={"flex space-between pagination"}>
               <span>
                   {shownArticlesNumber < filteredArticles.length ? shownArticlesNumber : filteredArticles.length} enregistrements sur un total de {filteredArticles.length} enregistrements
               </span>
                <Pagination currentPage={page} pageElement={shownArticlesNumber} totalElements={filteredArticles.length}
                            pageNeighbours={2} handleChangePage={handleChangePage}/>
            </div>
        </div>
    );
}

export default ArticleList;