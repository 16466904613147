import Hourglass from "../images/hourglass.svg";
import Draft from "../images/draft.svg"
import React from "react";
import "./Status.css"
export default function Status({status=null, notifications=null}) {

    switch (status) {
        case 0:
            return(
                <div className={"status waiting"}>
                    <img style={{left:"58%"}} src={Draft} alt=""/>
                </div>
            );
        case 1:
            return(
                <div className={"status waiting"}>
                    {notifications &&
                    <div className={"statusNotif"}>
                        {notifications}
                    </div>
                    }
                    <img src={Hourglass} alt=""/>
                </div>
            );
        case 2:
            return(
                <div className={"status validated"}>
                    ✓
                </div>
            );
        case 3:
            return(
                <div className={"status statusError"}>
                    X
                </div>
            );
        default:
            return  null;
    }
}