import React, {useEffect, useState} from 'react';

import './NewslettersList.css';
import Table from '../Table';
import {FilterAnd} from "../../utils/FilterList";
import Pagination from "../Pagination";
import API from "../../utils/ApiParams"
import {Redirect} from "react-router-dom";
import Preview from "../modals/Preview";
import MailHtmlGenerator from "../../utils/MailHtmlGenerator";
import ReactDOMServer from "react-dom/server";
import Loading from "../Loading";

function NewslettersList() {
    function copyNewsletter(id) {
        setToNewsletterEdition({navigate:true, id: id, action:"duplicate"});
    }
    function modifyNewsletter(id) {
        setToNewsletterEdition({navigate:true, id: id, action:"edit"});
    }
    function readNewsletter(id) {
        setToNewsletterEdition({navigate:true, id: id, action:"read"});
    }
    function previewNewsletter(id) {
        async function getNewsletter() {
            const response = await API.get("newsletters/"+id);
            return response.data;
        }
        getNewsletter().then(data=>{
            setCoverUrl(`${process.env.REACT_APP_API_URL}images/${data.coverImage.id}/${data.coverImage.name}`);
            setArticles(data.articles.map(art=>({
                id: art.id,
                title: art.title,
                categories: [...new Map(art.categories.map(item => [item.id, item])).values()].sort((a,b)=>{return a.position - b.position}),
                coverUrl: `${process.env.REACT_APP_API_URL}images/${art.thumbnail ? art.thumbnail.id : art.coverImage.id}/${art.thumbnail ? art.thumbnail.name : art.coverImage.name}`,
                text: art.shortText,
                author: art.author.name
            })));
            setIsFlash(data.isFlash);
            setShowPreview(true);
        })
    }
    const [toNewsletterEdition,setToNewsletterEdition] = useState({navigate: false,id: null});

    const [filteredNewsletters, setFilteredNewsletters] = useState([]);
    const [shownNewsletterNumber,setShownNewsletterNumber] = useState(5);
    const [searched,setSearched] = useState("");
    const [page, setPage] = useState(1);
    const [newsletters, setNewsletters] = useState([]);
    const [newslettersLoaded, setNewslettersLoaded] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [articles, setArticles] = useState([]);
    const [coverUrl,setCoverUrl] = useState(null);
    const [isFlash,setIsFlash] = useState(false);
    useEffect(()=>{
        async function getNewsletters() {
            const response = await API.get("newsletters");
            // console.log(response.data);
            return response.data.map(newsletter=>(
                {
                    title : {
                        format: "img",
                        url :  process.env.REACT_APP_API_URL+"images/"+newsletter.coverImage.id+"/"+newsletter.coverImage.name,
                        className : "tableImg",
                        value : newsletter.object?newsletter.object:newsletter.pushTitle?newsletter.pushTitle:""
                    },
                    format : {format: "txt",value : newsletter.format},
                    sendingDate : {format: "date",
                        value : newsletter.sendingDate ? new Date(newsletter.sendingDate) : new Date(newsletter.pushSendingDate)},
                    sent : {format:"txt", value: newsletter.sentMail ? `${newsletter.deliveredMail}/${newsletter.sentMail}` : " "},
                    action : {format: "button", id : newsletter.id, value : [
                            canModify(newsletter) && {handleClick: modifyNewsletter, text: "Modifier", className : "actionButton modifyButton"},
                            {handleClick: copyNewsletter, text: "Dupliquer", className : "actionButton duplicateButton"},
                            newsletter.format.toUpperCase() !== "PUSH" && {handleClick: previewNewsletter, text: "Prévisualiser", className : "actionButton previewButton"},
                        ]},
                    status : {format: "status",value : newsletter.status.index},
                    newsletter: {format: "txt", value: newsletter.id},
                    defaultAction: {handleClick:canModify(newsletter)?modifyNewsletter:readNewsletter, id:newsletter.id},
                }
            ))
        }
        getNewsletters().then(data=>{
            data.sort(function(a,b) {
                // By Date
                return (new Date(b["newsletter"].value) - new Date(a["newsletter"].value))

                // By newsletter id
                // return (b["newsletter"].value - a["newsletter"].value)
            })
            setNewsletters(data)
            setNewslettersLoaded(true);
        });
    }, [])
    function canModify(newsletter) {
        let modify = false;
        if(new Date(newsletter.sendingDate) > new Date( Date.now() - 1000 * (60 * 5) ))
            modify = true;
        if (new Date(newsletter.pushSendingDate) > new Date( Date.now() - 1000 * (60 * 5) ))
            modify = true;
        if(!newsletter.idEmailCampaign && newsletter.sendingDate)
            modify = true;
        if(!newsletter.idOneSignal && newsletter.pushSendingDate)
            modify = true;
        return modify;
    }
    function handleChangeNbNewsletter(event){
        if(event.target.value>0){
            setShownNewsletterNumber(event.target.value);
            handleChangePage(1);
        }
    }
    function handleChangeSearch(event){
        setSearched(event.target.value);
    }
    function handleChangePage(newPage){
        setPage(newPage);
    }
    useEffect(() => {
        setFilteredNewsletters(FilterAnd(newsletters,
            {title: searched}));
        handleChangePage(1);
    }, [searched,newsletters]);

    if (toNewsletterEdition.navigate){
        return (
            <Redirect push to={{
                pathname:"/newsletters/creation",
                state: {id : toNewsletterEdition.id, action : toNewsletterEdition.action}
            }}/>
        )
    }
    return (
        newslettersLoaded ?
        <div className={"newslettersList"}>
            <main className={"main"}>
                    <div className={"filterBar"}>
                        <div className={"filterNumber"}>
                            <input className={"inputNumber"} type="number" value={shownNewsletterNumber} onChange={handleChangeNbNewsletter}/>
                            <span> enregistrements par page</span>
                        </div>
                        <input className={`customInput ${searched!==""?"activeFilter":""}`} placeholder={"Rechercher"} type="text" value={searched} onChange={handleChangeSearch}/>
                    </div>
                    <Table headers={{
                        title: "Titre",
                        format : "Format",
                        sendingDate : "Date d'envoi",
                        sent : "Envoi",
                        action : "Action",
                        status: "Statut",
                        newsletter: "Newsletter"
                    }}
                           elements={filteredNewsletters}
                           currentPage={page}
                           elementsPerPage={shownNewsletterNumber}/>
                </main>
            <div className={"flex space-between pagination"}>
               <span>
                   {shownNewsletterNumber < filteredNewsletters.length ? shownNewsletterNumber : filteredNewsletters.length} enregistrements sur un total de {filteredNewsletters.length} enregistrements
               </span>
                <Pagination currentPage={page} pageElement={shownNewsletterNumber}
                            totalElements={filteredNewsletters.length} pageNeighbours={2}
                            handleChangePage={handleChangePage}/>
            </div>
            {(showPreview && articles.length > 0) &&
                <Preview component={
                    <iframe title={"Mail Preview"} srcDoc={ReactDOMServer.renderToStaticMarkup(MailHtmlGenerator(articles,coverUrl,isFlash))}
                            className={"previewContent"}></iframe>
                } boxWrapperClass={"previewMail"} isOpen={showPreview} handleClose={()=> setShowPreview(false)} iframe={true}/>
            }
        </div>
        : <Loading/>
    );
}

export default NewslettersList;
