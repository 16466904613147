import React from 'react';
import {
    Switch,
    Route,
    NavLink,
    useRouteMatch, Redirect
} from "react-router-dom";
import './Library.css'
import LibraryList from "./LibraryList";
import LibraryCreation from "./LibraryCreation";

function Library() {
    document.title = "Médiathèque";
    let url = useRouteMatch().url;
    return (
        <div className={"library"}>
            <div className={"flex"}>
                <NavLink to={`${url}/liste`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Médiathèque
                </NavLink>
                <NavLink to={`${url}/creation`} activeClassName={"activeMenu"} className={"col-6 menuButton"}>
                    Ajouter une image
                </NavLink>
            </div>
            <Switch>
                <Route path={`${url}/liste`}>
                    <LibraryList/>
                </Route>
                <Route path={`${url}/creation`} render={(props) => <LibraryCreation {...props} />}/>
                <Route>
                    <Redirect to={`${url}/liste`}/>
                </Route>
            </Switch>
        </div>
    );
}

export default Library;
