import React, {useState} from "react";
import "./Table.css";
import CustomCheckbox from "./CustomCheckbox";
import Status from "./Status";
export default function Table({headers,elements,currentPage,elementsPerPage, readOnly=false}) {
    const [sortedField, setSortedField] = useState({name : null,ascending: false});
    let sortedElements = [...elements]

    if (sortedField.name !== null) {
        sortedElements.sort((a, b) => {
            if (typeof a[sortedField.name].value === "string" || typeof b[sortedField.name].value === "string"){
                if (a[sortedField.name].value.toLowerCase() < b[sortedField.name].value.toLowerCase()) {
                    return sortedField.ascending?  -1 : 1;
                }
                if (a[sortedField.name].value.toLowerCase() > b[sortedField.name].value.toLowerCase()) {
                    return sortedField.ascending?  1 : -1;
                }
                return 0;
            }else if(Array.isArray(a[sortedField.name].value) && typeof a[sortedField.name].value[0] === "string"){
                if (a[sortedField.name].value[0].toLowerCase() < b[sortedField.name].value[0].toLowerCase()) {
                    return sortedField.ascending?  -1 : 1;
                }
                if (a[sortedField.name].value[0].toLowerCase() > b[sortedField.name].value[0].toLowerCase()) {
                    return sortedField.ascending?  1 : -1;
                }
                return 0;
            }
            else if(Array.isArray(a[sortedField.name].value) && typeof a[sortedField.name].value[0] !== "string" && a[sortedField.name].value[0].value){
                if(typeof a[sortedField.name].value[0].value === "string"){
                    if (a[sortedField.name].value[0].value.toLowerCase() < b[sortedField.name].value[0].value.toLowerCase()) {
                        return sortedField.ascending?  -1 : 1;
                    }
                    if (a[sortedField.name].value[0].value.toLowerCase() > b[sortedField.name].value[0].value.toLowerCase()) {
                        return sortedField.ascending?  1 : -1;
                    }
                    return 0;
                }else{
                    if (a[sortedField.name].value[0].value < b[sortedField.name].value[0].value) {
                        return sortedField.ascending?  -1 : 1;
                    }
                    if (a[sortedField.name].value[0].value > b[sortedField.name].value[0].value) {
                        return sortedField.ascending?  1 : -1;
                    }
                    return 0;
                }
            }
            else{
                if (a[sortedField.name].value < b[sortedField.name].value) {
                    return sortedField.ascending?  -1 : 1;
                }
                if (a[sortedField.name].value > b[sortedField.name].value) {
                    return sortedField.ascending?  1 : -1;
                }
                return 0;
            }
        });
    }
    function sortColumn(columnName) {
        if(sortedField.name === columnName)
            setSortedField({name : columnName,ascending : !sortedField.ascending})
        else
            setSortedField({name : columnName,ascending : true})
    }
    function getSortClass(columnName) {
        if(sortedField.name !== columnName)
            return (
                <span className={"order"}>▼</span>
            );
        else if(sortedField.ascending)
            return (
                <span className={"order sorted"}>▼</span>
            );
        else
            return (
                <span className={"order sorted"}>▲</span>
            );
    }

    function RenderCell(param) {
        switch (param.format) {
            case 'txt':
                return (
                    <div className={param.className}>{param.value}</div>
            );
            case 'img':
                return (
                    <div className={"flex nowrap"}>
                        <img className={param.className} src={param.url} alt=""/>
                        { param.value &&
                            <span className={"imgText"}> {param.value}</span>
                        }
                        </div>
                );
            case 'date':
                if(param.value)
                    return(
                        <div className={param.className}>
                            {param.value.toLocaleString("fr-FR",{
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"})}
                        </div>
                    );
                else return null;
            case 'list':
                return(
                    <ul className={"tableList"}>
                        {param.value.map((x,i)=>(
                            <li key={i} className={x.className}>{x.value}</li>
                        ))}
                    </ul>
                );
            case 'status':
                return <Status status={param.value} notifications={param.notifications}/>;
            case 'button':
                return(
                    <ul className={"tableList"}>
                        {param.value.map((el,i)=>(
                            <li key={i} onClick={(e) => {
                                e.stopPropagation();
                                el.handleClick(param.id);
                                }}
                                className={`buttonList ${el.className ? el.className : ""}`}>
                                {el.text}
                            </li>
                        ))}
                    </ul>
                );
            case 'checkbox':
                return (
                    <CustomCheckbox disabled={readOnly} id={param.id} checked={param.value} onChange={param.handleClick}/>
                );
            default:
                return null;
        }
    }

    return(
        <table className={"genericTable"}>
            <thead>
                <tr>
                    {Object.entries(headers).map(([key, header]) =>(
                        <th className={`${(header !== "Action")?"sort":""}`} key={key} onClick={()=> (header !== "Action") && sortColumn(key)}>
                            {header}
                            {(header !== "Action") && getSortClass(key)}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                  {sortedElements.slice((currentPage-1)*elementsPerPage,(currentPage)*elementsPerPage)
                      .map((element, index)=>(
                        <tr className={`${element["defaultAction"]?"clickableRow":""} ${element["rowClassName"] ? element["rowClassName"] : ""}`}
                            onClick={()=>(element["defaultAction"] && !readOnly) && element["defaultAction"].handleClick(element["defaultAction"].id)} key={index}>
                            {Object.entries(element).map(([key,x])=>(
                                x["format"] &&
                                 <td className={x["tdClassName"]} key={key}>
                                     <RenderCell id={x["id"]} format={x["format"]} value={x["value"]} className={x["className"]}
                                                 url={x["url"]} handleClick={x["handleClick"]} notifications={x["notifications"]}/>
                                 </td>
                            ))}
                        </tr>
                ))}
            </tbody>
        </table>
    )
}